import {
    Link as ChakraLink,
    Text,
    GridItem,
    Select,
    Box,
    SimpleGrid,
    Flex,
    Spacer
} from '@chakra-ui/react'

import { Card, ProgressBar } from "@tremor/react";
import React, {useState} from "react";

import axios from 'axios';

const SelectBudget = ({changeValue ,disabled, value, categoriesData, isInvalid, session, api_url, invoiceDate}) => {
  
  const [budgetSpendingData, setBudgetSpendingData] = useState({spending: {}, totalSpending: 0.0})
  
  const loadBudgetSpending = async (categoryId) =>{

    if(categoryId == undefined){
      return
    }

    try{
      const fetchDataCategory = axios.get(`${api_url}/invoices/spendingPerCategory/${categoryId}`, { 
        headers: { Authorization: `Bearer ${session.accessToken}` }
      });
      const dataCategory = await fetchDataCategory;
      
      if(dataCategory.status != 403 && dataCategory.status != 500){
        setBudgetSpendingData({...dataCategory.data});
      }
    }catch (ex){
      return
    }
  }

  React.useEffect(() => {
    loadBudgetSpending(value);
  }, [value])

  return(
    <div>
      <Box mt="4">
          <Text fontSize={"smaller"} mb={"1"} fontWeight={"bold"}>Budget</Text>
          <Select
            disabled={disabled}
            value={value}
            onChange={(e) =>{ changeValue(`category`, e.target.value)}}
            isInvalid={isInvalid}
          >
            <option value="" disabled selected>Please select the budget</option>
            {categoriesData.map((singleCategory)=>{
              return(
                <option key={singleCategory._id+"-select"} value={singleCategory._id}>{singleCategory.title}</option>
              )
            })}
          </Select>
        </Box>
        {categoriesData.map((singleCategory)=>{
        
          let monthlyBudget = "∞",
          annualBudget = "∞",
          totalBudget = "∞",
          budgetCurrency = "€",
          monthlySpending = Number(0).toFixed(0),
          annualSpending = Number(0).toFixed(0);
          
          let totalSpending = Number(0).toFixed(0);
          try {
            totalSpending = budgetSpendingData.totalSpending.toFixed(0);
          } catch (error) {

          }

          const monthNew = new Date(invoiceDate).getMonth().toString();
          const yearNew = new Date(invoiceDate).getFullYear().toString();

          if(yearNew in budgetSpendingData.spending){
            annualSpending = Number(budgetSpendingData.spending[yearNew].totalSpending).toFixed(0);
            if(monthNew in budgetSpendingData.spending[yearNew].monthlySpending){
              monthlySpending = Number(budgetSpendingData.spending[yearNew].monthlySpending[monthNew]).toFixed(0);
            }
          }

          let percentageMonthly = 0,
          percentageAnnual = 0,
          percentageTotal = 0;

          if("monthlyBudget" in singleCategory){
            monthlyBudget = Number(singleCategory.monthlyBudget).toFixed(0);
            let percentageMonthlyTemp = Number((Number(monthlySpending) / singleCategory.monthlyBudget).toFixed(2)) * 100;
            percentageMonthly = Number(percentageMonthlyTemp.toFixed(0));
          }
          if("annualBudget" in singleCategory){
            annualBudget =  Number(singleCategory.annualBudget).toFixed(0);
            let percentageAnnualTemp = Number((Number(annualSpending) / Number(singleCategory.annualBudget)).toFixed(2)) * 100;
            percentageAnnual = Number(percentageAnnualTemp.toFixed(0));
          }
          if("totalBudget" in singleCategory){
            totalBudget = Number(singleCategory.totalBudget).toFixed(0);
            let percentageTotalTemp = Number((Number(totalSpending) / Number(singleCategory.totalBudget)).toFixed(2)) * 100;
            percentageTotal = Number(percentageTotalTemp.toFixed(0));
          }
          if("currency" in singleCategory){
            if(singleCategory.currency == "EUR"){
              budgetCurrency = "€"
            }else if(singleCategory.currency == "USD"){
              budgetCurrency = "$"
            }else{
              budgetCurrency = singleCategory.currency
            }
          }


          


          if(singleCategory._id == value){
            return(
              <SimpleGrid key={singleCategory._id+"-data"} columns={3}>
                <Box mt="2">
                  <Text fontSize={"xs"}>Monthly</Text>
                  <Flex>
                    <Text fontSize={"sm"}>{monthlySpending} {budgetCurrency} &bull; {percentageMonthly}%</Text>
                    <Spacer></Spacer>
                    <Text fontSize={"sm"}>{monthlyBudget} {budgetCurrency}</Text>
                  </Flex>
                  <ProgressBar percentageValue={percentageMonthly} color="orange"/>
                </Box>
                <Box ml="3" mt="2">
                  <Text fontSize={"xs"}>Annual</Text>
                  <Flex>
                    <Text fontSize={"sm"}>{annualSpending} {budgetCurrency} &bull; {percentageAnnual}%</Text>
                    <Spacer></Spacer>
                    <Text fontSize={"sm"}>{annualBudget} {budgetCurrency}</Text>
                  </Flex>
                  <ProgressBar percentageValue={percentageAnnual} color="orange"/>
                </Box>
                <Box ml="3" mt="2">
                  <Text fontSize={"xs"}>Total</Text>
                  <Flex>
                    <Text fontSize={"sm"}>{totalSpending} {budgetCurrency} &bull; {percentageTotal}%</Text>
                    <Spacer></Spacer>
                    <Text fontSize={"sm"}>{totalBudget} {budgetCurrency}</Text>
                  </Flex>
                  <ProgressBar percentageValue={percentageTotal} color="orange"/>
                </Box>
              </SimpleGrid>
            )
          }

        })}
    </div>
  )
};

export default SelectBudget;