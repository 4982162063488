import {
    Link as ChakraLink,
    Text,
    GridItem,
    Select,
    Box,
    Grid,
    Heading,
    Button,
    Flex,
    Badge,
    Tooltip,
    Center,
    Divider
} from '@chakra-ui/react'

import React from "react";

import {IoCheckmarkCircle, IoCloseCircle} from 'react-icons/io5';


const InvoiceStatusElement = ({changeValue, typeName,invoiceType, invoiceStatus, invoiceTypesData, nextStepAllowed, createInvoice}) => {
  return(
    <Box>
        <Box mt="0">
        <Grid templateColumns='repeat(8, 1fr)' gap={3}>
            <GridItem colSpan={4}>
            <Heading as="h3" size={"md"}>
                {typeName} Status
            </Heading>
            </GridItem>
            <GridItem colSpan={4}>
            {nextStepAllowed && createInvoice == false &&
            <Button size={"sm"} float="right" colorScheme="green" onClick={()=>{changeValue("approve")}}>
                <IoCheckmarkCircle fontSize={"20px"} style={{marginRight: "5px"}}/>
                Approve & Save
            </Button>
            }
            {nextStepAllowed && createInvoice == false &&
            <Button size={"sm"} float="right" mr="4" colorScheme="red" onClick={()=>{changeValue("reject")}}>
                <IoCloseCircle fontSize={"20px"} style={{marginRight: "5px"}}/>
                Reject & Save
            </Button>
            }
            </GridItem>
        </Grid>
        </Box>            
        <Box width={"100%"} className="Test" mt="1">
        
        {invoiceType != undefined && invoiceType != "" && 
            <Flex mt="3">
            {invoiceTypesData.find(e => e._id == invoiceType) != undefined && invoiceTypesData.find(e => e._id == invoiceType).steps.map((singleStep, singleStepIndex)=>{
                let spacerElement = <Divider key={singleStep._id+"-divider"} mt="2" ml="1" mr="1"/>;
                
                if(singleStepIndex == invoiceTypesData.find(e => e._id == invoiceType).steps.length -1){
                    spacerElement = <div></div>
                } 
                let colorSchemeButton = "#E2E8F0";
                if(invoiceStatus == singleStep._id){
                    colorSchemeButton = singleStep.color;
                }
                return([
                    <Box key={singleStep._id+"edit-inv"}>
                    <Tooltip label={singleStep.description}>
                    <Center>
                        <Badge borderRadius='full' px='2' pr="28px" backgroundColor={colorSchemeButton} >
                        <IoCheckmarkCircle style={{float: "left", marginTop:"2px", marginRight: "3px"}}/>
                        <Text>{singleStep.title}</Text>
                        </Badge>
                    </Center>
                    </Tooltip>                          
                    </Box>,
                    spacerElement
                ])
            })}
            </Flex>
        }
        </Box>
        <Divider mt={5}></Divider>
    </Box>
  )
};

export default InvoiceStatusElement;