import {
    Link as ChakraLink,
    Text,
    Input,
    Box,
    Badge,
    Image,
    SimpleGrid,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    useDisclosure,
    Select,
    Heading,
    Avatar,
    Grid,
    GridItem,
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    Skeleton,
    Center,
    Divider,
    Spinner,
    Progress,
    Flex,
    Stat,
    StatLabel,
    StatNumber,
    StatHelpText,
    StatArrow,
    StatGroup,
    HStack,
    Textarea,
    Tooltip,
  } from '@chakra-ui/react'

import {IoSettings, IoExit,IoArrowUpCircle,IoCard, IoCheckmarkCircleOutline, IoBriefcase, IoPerson, IoMail,IoInformationCircle, IoCheckmarkDoneCircleSharp, IoCash,IoCheckmarkCircle, IoDownload ,IoCloudUpload,IoSave,IoDocumentAttachOutline , IoPersonAdd, IoAddCircle, IoShare,IoTrash, IoPersonCircleSharp, IoTimer, IoLockClosed, IoCloseCircle, IoChevronForward} from 'react-icons/io5';


import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { resolve } from 'path';
import Head from 'next/head';
import DatePicker from '@hassanmojab/react-modern-calendar-datepicker';
import axios from 'axios';
import {Element, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import InfiniteScroll from 'react-infinite-scroller';

const TravelExpenseListView = ({setTravelExpenseLoadHasMore ,travelExpenseLoadHasMore,travelExpenseFilter, loadTravelExpenses,setTravelExpenseFilter, userData, travelExpenseData, setTravelExpenseData, session, categoriesData, query,currency, setTravelExpense, travelExpense, onTravelExpenseOpen, setNewTravelExpense }) => {

    
    const [isLoading, setIsLoading] = useState(false);
    const [changedTravelExpenses, setChangedTravelExpenses] = useState([]);

    const [loadHasMore, setLoadHasMore] = useState(false);
    

    const [monthlyCost, setMonthlyCost] = useState({});

    const api_url = process.env.NEXT_PUBLIC_API_URL

    const emptyInvoice = {status: "private",category:"briefcase",uuid:"",shop:"Test Invo",total:"100",tax:"19",totalNet:"81",mwst:"19",date:new Date().toUTCString(),items:[],scanAttached:"",location:{},paymentType:"",invoiceText:"", author: "", authorData:[{name:""}], currency: "EUR", changelog: [], taxPositions: [{taxRate: "19", value: ""}]};
  
    const [travelExpenseUpdateLoading, setTravelExpenseUpdateLoading] = useState(false);

    let createTravelExpense = true


  React.useEffect(() => {
    loadTravelExpenses();
  }, [travelExpenseFilter])

  const toggleTravelExpenseSelect =async (ev, i) => {
    ev.stopPropagation();
    ev.preventDefault();
    let rekeningDataTemp = travelExpenseData;
    if(rekeningDataTemp[i]["selected"] == undefined || rekeningDataTemp[i]["selected"] == false){
      rekeningDataTemp[i]["selected"] = true;
    }else{
      rekeningDataTemp[i]["selected"] = false;
    }

    setTravelExpenseData([...rekeningDataTemp]);
  }


    const openTravelExpense = async(index)=>{
        setTravelExpense(travelExpenseData[index]);
        setNewTravelExpense(false);
        onTravelExpenseOpen()
    }

/*
  const searchForTextInput = async (e) =>{
    let searchKey = e.target.value.toLowerCase()
    setSearchForText(searchKey)
    searchFor(searchKey, searchForStatus, searchForUser);
  }
*/
  const searchForInput = async (key, value) =>{

    let travelExpenseFilterTemp = travelExpenseFilter;
    let filterExists = travelExpenseFilterTemp.findIndex((e)=> e.key == key);

    if(value == "" && filterExists != -1){
      travelExpenseFilterTemp.splice(filterExists, 1)
    }

    if(filterExists == -1 && value != ""){
      travelExpenseFilterTemp.push({key: key, value: value});
    }else if(filterExists >= 0 && value != ""){
      travelExpenseFilterTemp[filterExists] = {key: key, value: value};
    }
    
    setTravelExpenseFilter([...travelExpenseFilterTemp]);
  }




  const loadFunc = async(numberScrolled)=>{
    let offset = numberScrolled * 200,
    limit = 200,
    travelExpenseFilterTemp = travelExpenseFilter,
    filterExistsOffset = travelExpenseFilterTemp.findIndex((e)=> e.key == "offset"),
    filterExistsLimit = travelExpenseFilterTemp.findIndex((e)=> e.key == "limit");

    if(filterExistsOffset == -1){
      travelExpenseFilterTemp.push({key: "offset", value: offset});
      travelExpenseFilterTemp.push({key: "limit", value: limit});
    }
    setTravelExpenseLoadHasMore(false);
    setTravelExpenseFilter([...travelExpenseFilterTemp]);

  }

  /*
  const searchForUserInput = async (e) =>{
    let user = e.target.value.toLowerCase();
    setSearchForUser(user)
    searchFor(searchForText, searchForStatus, user);
  }
  

  const searchFor = async (text, status, user) =>{
    let tempTotalValue = 0;
    let newRekeningData = [],
    searchValue = text;
    rekeningDataOriginal.map((item)=>{
      let add = false;

      if(item.shop.toLowerCase().includes(searchValue) || item.total.includes(searchValue)){
        add = true;
      }

      if(add == true && status != ""){
        if(item.status == status){
          add = true;
        }else{
          add = false;
        }
      }
      
      if(add == true && user != ""){
        if(item.author == user){
          add = true;
        }else{
          add = false;
        }
      }

      if(add == true){
        newRekeningData.push(item);
        tempTotalValue = tempTotalValue + parseFloat(item.total);
      }
    })
    setTotalVisibleValue(""+tempTotalValue.toFixed(2));

    if(text == "" && status == "" && user == ""){
      newRekeningData = rekeningDataOriginal
    }
    calculateMonthlyCost(newRekeningData)
    setRekeningData(newRekeningData);
  }
*/
    let lastDate = undefined;

    let subscriptionElement = (
    <Box width={"100%"} >
       <SimpleGrid mb={"2"} columns={16} spacing={5}>
        <GridItem colSpan={7}>
          <Heading  as='h2' size='lg' ml="0%" mb="2" style={{width: "100%"}}>
            All Travel Expenses
          </Heading>
        </GridItem>
        <GridItem colSpan={8}>
          <Heading  as='h2' size='lg' ml="0%" mb="2" style={{textAlign: "right", width: "100%"}}>
            
          </Heading>
        </GridItem>
      </SimpleGrid>
      <SimpleGrid mb={"2"}columns={16} spacing={5}>
        <GridItem colSpan={5}>
          <Heading  as='h4' size='sm' mb="1">Search</Heading>
          <Input type={"search"} placeholder="Search..."></Input>
        </GridItem>
        <GridItem colSpan={3}>
          <Heading  as='h4' size='sm' mb="1">Status</Heading>
          <Select onChange={(e)=>{searchForInput("status" ,e.target.value.toLowerCase())}}>
            <option value="">All</option>
            <option value="private">In Progress</option>
            <option value="requested">Waiting for Refund</option>
            <option value="refunded">Refund Done</option>
          </Select>
        </GridItem>
        <GridItem colSpan={3}>
          <Heading  as='h4' size='sm' mb="1">All User</Heading>
          <Select onChange={(e)=>{searchForInput("searchUser" ,e.target.value)}} >
            <option value="">All</option>
            {userData.map((singleUser)=>{
              return(
                <option value={singleUser._id}>{singleUser.username}</option>
              )
            })}
          </Select>
        </GridItem>
        <GridItem colSpan={5}>
        </GridItem>
      </SimpleGrid>
      <InfiniteScroll
        pageStart={0}
        loadMore={loadFunc}
        hasMore={travelExpenseLoadHasMore}
        loader={<div className="loader" key={0}>Loading ...</div>}
      >
    <SimpleGrid style={{paddingBottom: "50px"}} columns={1} spacing={3}>
        {travelExpenseData.map((item, index) => {
                
                const date= new Date(item.startDate).toLocaleDateString('de-DE');
                const endDate= new Date(item.endDate).toLocaleDateString('de-DE');

                const dateArray = ["January", "February", "March", "April", "Mai", "June", "July", "August", "September", "October", "November", "December"];

                var invDate = new Date(item.startDate).toLocaleString();
                //const dateArray = new Date(item.date).getMonth();
                const dateNew = new Date(item.startDate).getMonth();
                const yearNew = new Date(item.startDate).getFullYear();
                let addDateHeader = undefined,
                addDateHeaderYear = "",
                addDateHeaderCost = undefined;
                
                let invoiceCurrency = item.currency;
                if(invoiceCurrency == "EUR" || invoiceCurrency == "" || invoiceCurrency == undefined){
                  invoiceCurrency = "€"
                }else if(invoiceCurrency == "USD"){
                  invoiceCurrency = "$"
                }else if(invoiceCurrency == "JPY"){
                  invoiceCurrency = "¥"
                }else if(invoiceCurrency == "GBP"){
                  invoiceCurrency = "£"
                }else if(invoiceCurrency == "INR"){
                  invoiceCurrency = "₹"
                }else if(invoiceCurrency == "CAD"){
                  invoiceCurrency = "$"
                }

                let itemTotal = Number(item.compensation.replace(",", ".")).toFixed(2);
                
                if(item.invoices.length != 0){
                    item.invoices.map((singleInvoice)=>{
                      itemTotal = (Number(itemTotal) + Number(singleInvoice.total)).toFixed(2); 
                    })
                }else{

                }
                
                let itemTotalString = itemTotal;


                if(dateNew != lastDate){
                    lastDate = dateNew;
                    addDateHeader = dateNew;
                    addDateHeaderYear = yearNew.toString();
                    addDateHeaderCost = monthlyCost[yearNew+"_"+dateNew];
                }
                if(index == travelExpenseData.length -1){
                    lastDate = undefined
                }

                //Mark new inovices
                let backgroundColorInvoice = "white"
                if(changedTravelExpenses.includes(item._id)){
                  backgroundColorInvoice = "#fff4e1"
                }

                let iconData = categoriesData.find((e)=> e._id == item.category)

                let IconWeb = IoBriefcase;
                if(iconData != undefined){
                  IconWeb = iconData.iconWebElement;
                }

                let invoceSelectClassList = "rekening-invoice-item";
                if(item.selected != undefined && item.selected == true){
                  invoceSelectClassList = "rekening-invoice-item selected"
                }

                return(
                  <div>
                  {addDateHeader != undefined &&

                <SimpleGrid key={index} mb={"2"}columns={16} spacing={5}>
                    <GridItem colSpan={8}>
                      <Heading  as='h3' size='md' ml="0%" mt="5" style={{width: "100%"}}>
                      {addDateHeaderYear} {dateArray[addDateHeader]}
                      </Heading>
                    </GridItem>
                    <GridItem colSpan={7}>
                      <Heading  as='h3' size='md' textAlign="right" ml="0%" mt="5" style={{width: "100%"}}>
                        {addDateHeaderCost}
                      </Heading>
                    </GridItem>
                  </SimpleGrid>
                  }
                  <Box className={invoceSelectClassList} padding="3" backgroundColor={backgroundColorInvoice} borderWidth='1px' borderRadius='lg' style={{transition: "all 0.2s"}}
                    _hover={{
                      cursor: "pointer",
                      backgroundColor: "#f4f4f4"
                    }}
                    onClick={()=>{openTravelExpense(index)}}
                  >
                    <Element name={item._id}></Element>
                    <Grid templateColumns='repeat(16, 1fr)' gap={4}>
                      <GridItem colSpan={1}>
                        <HStack spacing='5px'>
                        <Box onClick={(ev)=>{toggleTravelExpenseSelect(ev, index)}} className={"list-checkbox"}>
                          <Box className='list-checkbox-inner'/>
                        </Box>
                        {item.authorData.length != 0 &&
                          <Avatar marginRight={2} borderRadius={10} height={45} width={45} className='rekening-invoice-avatar' name={item["authorData"][0]["username"]} />
                        }
                        {item.authorData.length == 0 &&
                          <Avatar marginRight={2} borderRadius={10} height={45} width={45} className='rekening-invoice-avatar' name={""} />
                        }
                        <div className='rekening-invoice-icon'>
                          <IconWeb style={{marginLeft: "2px", marginTop: "2px"}} fontSize={"30px"}></IconWeb>
                        </div>
                        </HStack>
                      </GridItem>
                      <GridItem colSpan={5}>
                        <Heading  as='h4' size='sm'>
                          {item.title}
                        </Heading>
                        <Box display='flex' alignItems='baseline'>
                            {/*<Badge borderRadius='full' px='2' colorScheme='teal'>
                              Schritt {index}
                            </Badge>*/}
                            <Box
                              color='gray.500'
                              fontWeight='semibold'
                              letterSpacing='wide'
                              textTransform='uppercase'
                              ml='0'
                            >
                              {date} - {endDate}
                            </Box>
                          </Box>
                      </GridItem>
                      <GridItem colSpan={4}>
                        {item.invoices == "" &&
                          <Heading  as='h4' size='sm'>
                            Without Invoices
                          </Heading>
                        } 
                        {(item.invoices != undefined && item.invoices.length > 0) &&
                          <Heading  as='h4' size='sm'>
                            {item.invoices.length} Invoices
                          </Heading>
                        } 
                        <Box display='flex' alignItems='baseline'>
                          {(item.status == undefined || item.status == "private") &&
                          <Badge borderRadius='full' px='2' mt="1" pr="28px" colorScheme={"red"}>
                            <IoLockClosed style={{float: "left", marginTop:"2px", marginRight: "3px"}}/>
                            <Text>In Progress</Text>
                          </Badge>
                          }
                          {(item.status == "requested") &&
                          <Badge borderRadius='full' px='2' mt="1" pr="28px" colorScheme={"orange"}>
                            <IoTimer style={{float: "left", marginTop:"3px", marginRight: "3px"}}/>
                            <Text>Waiting for refund</Text>
                          </Badge>
                          }
                          {(item.status == "refunded") &&
                          <Badge borderRadius='full' px='2' mt="1" pr="28px" colorScheme={"green"}>
                            <IoCheckmarkCircle style={{float: "left", marginTop:"2px", marginRight: "3px"}}/>
                            <Text>Refund done</Text>
                          </Badge>
                          }
                        </Box>
                      </GridItem>
                      <GridItem colSpan={5} textAlign="right">
                        <Heading  as='h4' size='sm'>
                          {itemTotalString}{currency}
                        </Heading>
                        <Box display='flex' alignItems='baseline'>
                            {/*<Badge borderRadius='full' px='2' colorScheme='teal'>
                              Schritt {index}
                            </Badge>*/}
                            <Box
                              textAlign="right"
                              color='gray.500'
                              fontWeight='semibold'
                              letterSpacing='wide'
                              textTransform='uppercase'
                              ml='0'
                              width="100%"
                            >
                              {item.locationTo}
                            </Box>
                          </Box>
                      </GridItem>
                      <GridItem>
                        <div className='rekening-item-right'>
                          <IoChevronForward fontSize={"35px"}></IoChevronForward>
                        </div>
                      </GridItem>
                    </Grid>
                    </Box>

                  </div>
              )})}
        </SimpleGrid>
        </InfiniteScroll>
    </Box>
    );

    return subscriptionElement;
};

export default TravelExpenseListView;