import {
    Link as ChakraLink,
    Text,
    Box,
    Select,
    Divider,
    Heading,
    Grid,
    GridItem,
    Input,
    cookieStorageManager,

} from '@chakra-ui/react'

import DatePicker from '@hassanmojab/react-modern-calendar-datepicker';
import React, {useState} from "react";


const TravelExpensesElement = ({changeText ,countryListAllowance, travelExpense, readOnlyAdminFields, editInvoiceErrorArray, invoiceTypeNextStepAllowed}) => {

    const [selectedDayRange, setSelectedDayRange] = useState({
      from: null,
      to: null
    });

    const [countTravelDays, setCountTravelDays] = useState(0);
    const [startTime, setStartTime] = useState("08:00");
    const [endTime, setEndTime] = useState("18:00");

    React.useEffect(() => {

      const utcDateStart = new Date(travelExpense.startDate);
      const localDateStart = utcDateStart.toLocaleDateString();  // Format: MM/DD/YYYY (may vary based on locale)
      const localTimeStart = utcDateStart.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false }); // Format: HH:mm (24-hour format)
      let startDate = {year: utcDateStart.getFullYear(), month: utcDateStart.getMonth() +1, day: utcDateStart.getDate()}
      setStartTime(localTimeStart);


      const utcDateEnd = new Date(travelExpense.endDate);
      const localDateEnd = utcDateEnd.toLocaleDateString();  // Format: MM/DD/YYYY (may vary based on locale)
      const localTimeEnd = utcDateEnd.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false }); // Format: HH:mm (24-hour format)
      let endDate = {year: utcDateEnd.getFullYear(), month: utcDateEnd.getMonth() +1, day: utcDateEnd.getDate()}
      setEndTime(localTimeEnd);

      setSelectedDayRange({from: startDate, to: endDate})

      console.log(travelExpense.startDate);
    }, [travelExpense] )
    
    /*
    React.useEffect(() => {
        updateFromToDate()
    }, [isTravelExpenseOpen] )
    */
   const onChangeTime =async (e, key) => {
    console.log(e.currentTarget.value)
    let time = e.currentTarget.value.split(":");
    let startDate;
    if(key == "startDate"){
      startDate = new Date(Date.UTC(selectedDayRange.from.year, selectedDayRange.from.month -1, selectedDayRange.from.day));
      setStartTime(e.currentTarget.value);
    }else{
      startDate = new Date(Date.UTC(selectedDayRange.to.year, selectedDayRange.to.month -1, selectedDayRange.to.day));
      setEndTime(e.currentTarget.value);
    }
    startDate.setHours(time[0])
    startDate.setMinutes(time[1])
    changeText(key, startDate.toUTCString());
    
   }
    const changeInvoiceDate = async(newDate)=>{
      console.log(newDate);
        if(newDate.from != null && newDate.to != null){

            let utcDateEnd = new Date(Date.UTC(newDate.to.year, newDate.to.month -1, newDate.to.day));
            let utcDateStart = new Date(Date.UTC(newDate.from.year, newDate.from.month -1, newDate.from.day));
            changeText("startDate", utcDateStart.toISOString());
            changeText("endDate", utcDateEnd.toISOString());

            const oneDayInMilliseconds = 1000 * 60 * 60 * 24; // 1000 milliseconds * 60 seconds * 60 minutes * 24 hours
            const differenceInMilliseconds = utcDateEnd.getTime() - utcDateStart.getTime();
            const numberOfDays = Math.round(differenceInMilliseconds / oneDayInMilliseconds)+1;
            setCountTravelDays(numberOfDays);
        }
        setSelectedDayRange(newDate);
        /*
        const invoiceDate = new Date(newDate.year, newDate.month -1, newDate.day);
        invoiceDate.setHours(12);
        let openInvoiceData = newInvoice;
        if(createInvoice == false){
          openInvoiceData = rekeningData[selectedInvoice];
        }
        openInvoiceData.date = invoiceDate.toUTCString();
        saveInvoiceData(openInvoiceData);
        */
    }
  
    const renderCustomInput = ({ ref }) => (
        <Input
          readOnly
          ref={ref} // necessary
          style={{
            width: "100%"
          }}
          placeholder="Select the date range"
          value={selectedDayRange.from && selectedDayRange.to ? `${selectedDayRange.from.day}.${selectedDayRange.from.month}.${selectedDayRange.from.year} - ${selectedDayRange.to.day}.${selectedDayRange.to.month}.${selectedDayRange.to.year}` : ''}
        />
    )

  return(
    <Box mt="2">
        <Divider mt="4"></Divider>
            <Heading mt="4" as="h3" size={"md"}>
              Duration & Transportation: {countTravelDays} Day(s)
            </Heading>
            <Grid templateColumns='repeat(4, 1fr)' gap={3}>
              <GridItem colSpan={2}>
                <Box mt="4">
                    <Text fontSize={"smaller"} mb={"1"} fontWeight={"bold"}>Start & End Date</Text>
                    <DatePicker
                      renderInput={renderCustomInput} 
                      value={selectedDayRange}
                      onChange={changeInvoiceDate}
                      colorPrimary="#F39F50" 
                    ></DatePicker>
                </Box>
              </GridItem>
              <GridItem colSpan={1}>
                <Box mt="4">
                    <Text fontSize={"smaller"} mb={"1"} fontWeight={"bold"}>Start Time</Text>
                    <Input 
                      value={startTime}
                      onChange={(e)=>{onChangeTime(e, "startDate")}}
                      className="rekening-time-picker"
                      type="time"
                      isInvalid={editInvoiceErrorArray.includes("startDate")}
                      
                    ></Input>
                </Box>
              </GridItem>
              <GridItem colSpan={1}>
                <Box mt="4">
                    <Text fontSize={"smaller"} mb={"1"} fontWeight={"bold"}>End Time</Text>
                    <Input 
                      value={endTime}
                      onChange={(e)=>{onChangeTime(e, "endDate")}}
                      className="rekening-time-picker"
                      type="time"
                      isInvalid={editInvoiceErrorArray.includes("endDate")}
                    ></Input>
                </Box>
              </GridItem>
            </Grid>
            <Grid templateColumns='repeat(4, 1fr)' gap={3}>
              <GridItem colSpan={2}>
                <Box mt="4">
                  <Text fontSize={"smaller"} mb={"1"} fontWeight={"bold"}>Country of Destination</Text>
                  <Select
                    value={travelExpense.country}
                    onChange={evt => changeText(`country`, evt.target.value)}
                  >
                    {countryListAllowance.map((country)=>{
                      return(
                        <option key={country.code} value={country.code}>{country.name}</option>
                      )
                    })}
                    </Select>
                </Box>
              </GridItem>
              <Box mt="4">
                <Text fontSize={"smaller"} mb={"1"} fontWeight={"bold"}>Arrival/Departure</Text>
                <Input readOnly={readOnlyAdminFields} onChange={evt => changeText(`allowanceSmall`, evt.target.value)} type="number" value={travelExpense.allowanceSmall}></Input>
              </Box>
              <Box mt="4">
                <Text fontSize={"smaller"} mb={"1"} fontWeight={"bold"}>Full Day</Text>
                <Input readOnly={readOnlyAdminFields} onChange={evt => changeText(`allowanceBig`, evt.target.value)} type="number"  value={travelExpense.allowanceBig}></Input>
              </Box>
            </Grid>
            <Grid templateColumns='repeat(2, 1fr)' gap={3}>
                <GridItem colSpan={1}>
                    <Box mt="4">
                        <Text fontSize={"smaller"} mb={"1"} fontWeight={"bold"}>Travel Start Point</Text>
                        <Input
                            placeholder='Type here...'
                            value={travelExpense.locationFrom}
                            onChange={evt => changeText(`locationFrom`, evt.target.value)}
                            isInvalid={editInvoiceErrorArray.includes("locationFrom")}
                        />
                    </Box>
                </GridItem>
                <GridItem colSpan={1}>
                    <Box mt="4">
                        <Text fontSize={"smaller"} mb={"1"} fontWeight={"bold"}>Travel Destination</Text>
                        <Input
                            placeholder='Type here...'
                            value={travelExpense.locationTo}
                            onChange={evt => changeText(`locationTo`, evt.target.value)}
                            isInvalid={editInvoiceErrorArray.includes("locationTo")}
                        />
                    </Box>
                </GridItem>
            </Grid>
            <Grid templateColumns='repeat(2, 1fr)' gap={3}>
                <GridItem colSpan={1}>
                    <Box mt="4">
                        <Text fontSize={"smaller"} mb={"1"} fontWeight={"bold"}>Transportation</Text>
                        <Select 
                          value={travelExpense.transportation}
                          onChange={evt => changeText(`transportation`, evt.target.value)}
                          isInvalid={editInvoiceErrorArray.includes("transportation")}
                        >
                            <option value="train">Train</option>
                            <option value="plain">Plane</option>
                            <option value="own car">Own Car</option>
                            <option value="business car">Business Car</option>
                        </Select>
                    </Box>
                </GridItem>
                <GridItem colSpan={1}>
                    <Box mt="4">
                        <Text fontSize={"smaller"} mb={"1"} fontWeight={"bold"}>Travel Distance (km)</Text>
                        <Input
                            placeholder='Type here...'
                            value={travelExpense.transportationDistance}
                            onChange={evt => changeText(`transportationDistance`, evt.target.value)}
                            isInvalid={editInvoiceErrorArray.includes("transportationDistance")}
                        />
                    </Box>
                </GridItem>
            </Grid>

            <Divider mt="4"></Divider>
            <Heading mt="4" as="h3" size={"md"}>
              Provided Meals
            </Heading>

            <Grid templateColumns='repeat(3, 1fr)' gap={3}>
                <GridItem colSpan={1}>
                    <Box mt="4">
                        <Text fontSize={"smaller"} mb={"1"} fontWeight={"bold"}>Paid Breakfasts</Text>
                        <Input
                            placeholder='Type here...'
                            value={travelExpense.breakfasts}
                            type="number"
                            min="0"
                            max={countTravelDays}
                            onChange={evt => changeText(`breakfasts`, parseInt(evt.target.value))}
                        />
                    </Box>
                </GridItem>
                <GridItem colSpan={1}>
                    <Box mt="4">
                        <Text fontSize={"smaller"} mb={"1"} fontWeight={"bold"}>Paid Lunches</Text>
                        <Input
                            placeholder='Type here...'
                            value={travelExpense.lunches}
                            type="number"
                            min="0"
                            max={countTravelDays}
                            onChange={evt => changeText(`lunches`, parseInt(evt.target.value))}
                        />
                    </Box>
                </GridItem>
                <GridItem colSpan={1}>
                    <Box mt="4">
                        <Text fontSize={"smaller"} mb={"1"} fontWeight={"bold"}>Paid Dinners</Text>
                        <Input
                            placeholder='Type here...'
                            value={travelExpense.dinners}
                            type="number"
                            min="0"
                            max={countTravelDays}
                            onChange={evt => changeText(`dinners`, parseInt(evt.target.value))}
                        />
                    </Box>
                </GridItem>
            </Grid>
    </Box>
  )
};

export default TravelExpensesElement;