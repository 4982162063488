import {
    Link as ChakraLink,
    Text,
    Input,
    Box,
    Badge,
    Image,
    SimpleGrid,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    useDisclosure,
    Select,
    Heading,
    Avatar,
    Grid,
    GridItem,
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    Skeleton,
    Center,
    Divider,
    Spinner,
    Progress,
    Flex,
    Stat,
    StatLabel,
    StatNumber,
    StatHelpText,
    StatArrow,
    StatGroup,
    HStack,
    Textarea,
    Tooltip,
  } from '@chakra-ui/react'

import {IoSettings, IoExit,IoArrowUpCircle,IoCard, IoCheckmarkCircleOutline, IoBriefcase, IoPerson, IoMail,IoInformationCircle, IoCheckmarkDoneCircleSharp, IoCash,IoCheckmarkCircle, IoDownload ,IoCloudUpload,IoSave,IoDocumentAttachOutline , IoPersonAdd, IoAddCircle, IoShare,IoTrash, IoPersonCircleSharp, IoTimer, IoLockClosed, IoCloseCircle, IoPrint} from 'react-icons/io5';


import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { resolve } from 'path';
import Head from 'next/head';
import DatePicker from '@hassanmojab/react-modern-calendar-datepicker';
import InvoiceStatusElement from './InvoiceStatusElement';

const TravelExpensesManager = ({ rekeningData, invoiceTypesData, loadTravelExpenses, userData, session, categoriesData, onTravelExpenseClose, isTravelExpenseOpen, travelExpense, setTravelExpense, userNotification, newTravelExpense}) => {

    const countryListAllowance = [
      {
        name: "Germany",
        small: 14,
        large: 28,
        code: "DE"
      },
      {
        name: "France",
        small: 29,
        large: 44,
        code: "FR"
      },
      {
        name: "Netherlands",
        small: 32,
        large: 47,
        code: "NL"
      },
      {
        name: "Poland",
        small: 20,
        large: 29,
        code: "PL"
      },
      {
        name: "Austria",
        small: 27,
        large: 40,
        code: "AT"
      },
      {
        name: "United Kingdome",
        small: 30,
        large: 45, 
        code: "UK"
      },


    ]  
  
    const [travelExpenseUpdateLoading, setTravelExpenseUpdateLoading] = useState(false);


    const [totalCost, setTotalCost] = useState(0)
    const [totalNet, setTotalNet] = useState(0)

    const [selectedDay, setSelectedDay] = useState({
      year: new Date().getFullYear(),
      month: new Date().getMonth() +1,
      day: new Date().getUTCDate(),
    });


    React.useEffect(() => {
        calculateTotalCost()
    }, [travelExpense] )

    React.useEffect(() => {
        updateFromToDate()
    }, [isTravelExpenseOpen] )

  
    const [selectedDayRange, setSelectedDayRange] = useState({
        from: null,
        to: null
      });
    const renderCustomInput = ({ ref }) => (
        <Input
          readOnly
          ref={ref} // necessary
          style={{
            width: "100%"
          }}
          placeholder="Select the date range"
          value={selectedDayRange.from && selectedDayRange.to ? `${selectedDayRange.from.day}.${selectedDayRange.from.month}.${selectedDayRange.from.year} - ${selectedDayRange.to.day}.${selectedDayRange.to.month}.${selectedDayRange.to.year}` : ''}
        />
      )
  
    const updateFromToDate = ()=>{
      try {
        let startDate = new Date(travelExpense.travelExpense.startDate),
        endDate = new Date(travelExpense.travelExpense.endDate);
        let setData = {from: {
          month: startDate.getMonth()+1,
          year: startDate.getFullYear(),
          day: startDate.getUTCDate()
        }, to: {
          month: endDate.getMonth()+1,
          year:endDate.getFullYear(),
          day: endDate.getUTCDate()
        }
      }
        setSelectedDayRange(setData)  
      } catch (error) {
        
      }
      
    }
  
    const createNewTravelExpenseRequest = async (data, bulkUpload = false) =>{
        let body = prepearTravelExpenseData(data)
    
        if(data == undefined){
          return;
        }
        
        const fetchData = await fetch(`${api_url}/travelExpense`, {
          credentials: "include",
          method: "POST",
          headers: {
            'Authorization': `Bearer ${session.accessToken}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(body)
        })
    
        const fetchDataJson = await fetchData.json();
    
        if("statusCode" in fetchDataJson){
          userNotification("Creation Error", fetchDataJson["message"][0], "error");
        }else{
          userNotification("New Travel Expense Created","Your new travel expense has been successfully created","success");
          loadTravelExpenses();
          onTravelExpenseClose()
        }
        //const data = await fetchData.();
    
        setTravelExpenseUpdateLoading(false);
    
        //await loadInvoices()
        /*if(!bulkUpload  && "statusCode" in fetchDataJson == false){
          setChangedInvoices([fetchDataJson._id]);
          setTimeout(()=>{
            scroller.scrollTo(fetchDataJson._id, {
              duration: 500,
              delay: 0,
              smooth: true,
              offset: -100, // Scrolls to element + 50 pixels down the page
            })
          },200)
        }
        */
        return fetchDataJson;
    }

    const deleteTravelExpense =async () => {
      setTravelExpenseUpdateLoading(true);
      const id = travelExpense._id
      const fetchData = await fetch(`${api_url}/travelExpense/${id}`, {
        method: "DELETE",
        headers: {
          'Authorization': `Bearer ${session.accessToken}`,
        },
      })
      loadTravelExpenses()
      userNotification("Delete Successfull", "Your travel expense has been successfully deleted", "success");
      onTravelExpenseClose()
      setTravelExpenseUpdateLoading(false);
    }
    
    const calculateTravelExpenseCost = async (body) => {
      let travelExpenseTemp = travelExpense;
     
      if(
        travelExpense.title == "" ||
        travelExpense.allowanceBig == "" ||
        travelExpense.allowanceSmall == "" ||
        travelExpense.breakfasts == "" ||
        travelExpense.lunches == "" ||
        travelExpense.dinners == "" ||
        travelExpense.startDate == "" ||
        travelExpense.endDate == ""||
        travelExpense.description == ""||
        travelExpense.locationTo == ""||
        travelExpense.transportationDistance == ""
      ){
        return;
      }
      const fetchData = await fetch(`${api_url}/travelExpense/calculate`, {
        credentials: "include",
        method: "POST",
        headers: {
          'Authorization': `Bearer ${session.accessToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      })
  
      const fetchDataJson = await fetchData.json();
  
      if("statusCode" in fetchDataJson){
        //userNotification("Creation Error", fetchDataJson["message"][0], "error");
      }else{
        travelExpenseTemp.compensation = fetchDataJson.cost;
        setTravelExpense({...travelExpenseTemp});

        //userNotification("New Travel Expense Created","Your new travel expense has been successfully created","success");
        //onTravelExpenseClose()
      }
    }

    const prepearTravelExpenseData = (data)=>{
      let invoiceIdArray = [];
      data.invoices.map((singleInvoice)=>{
        invoiceIdArray.push(singleInvoice._id);
      })
      data.invoices = invoiceIdArray;
      
      return data;
    }
    
    const printTravelExpense = ()=>{
      window.open("/travelExpensePrint/"+travelExpense._id, "_blank").focus()
    }

    const updateTravelExpense = async () =>{
      setTravelExpenseUpdateLoading(true);
      let body = prepearTravelExpenseData(travelExpense);

      const fetchData = await fetch(`${api_url}/travelExpense/${body._id}`, {
        method: "PATCH",
        headers: {
          'Authorization': `Bearer ${session.accessToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      })
      const fetchDataJson = await fetchData.json();
      if("statusCode" in fetchDataJson){
        userNotification("Update Error", fetchDataJson["message"][0], "error");
      }else{
        userNotification("Update Successfull", "Your travel expense has been successfully updated", "success");
        loadTravelExpenses();
        onTravelExpenseClose()
        //setChen([fetchDataJson["_id"]]);
      }
      setTravelExpenseUpdateLoading(false);

      //setEditInvoiceModified(false);
    }

    
    const createNewTravelExpense = async () => {
        setTravelExpenseUpdateLoading(true);
        createNewTravelExpenseRequest(travelExpense);
    }
    
    
    const changeInvoiceDate = async(newDate)=>{
        if(newDate.from != null && newDate.to != null){
            onChangeTextTravelExpense("startDate", new Date(Date.UTC(newDate.from.year, newDate.from.month -1, newDate.from.day)).toUTCString());
            onChangeTextTravelExpense("endDate", new Date(Date.UTC(newDate.to.year, newDate.to.month -1, newDate.to.day)).toUTCString());
        }
        setSelectedDayRange(newDate);
        /*
        const invoiceDate = new Date(newDate.year, newDate.month -1, newDate.day);
        invoiceDate.setHours(12);
        let openInvoiceData = newInvoice;
        if(createInvoice == false){
          openInvoiceData = rekeningData[selectedInvoice];
        }
        openInvoiceData.date = invoiceDate.toUTCString();
        saveInvoiceData(openInvoiceData);
        */
    }
    const editTravelExpenseStatusUpdate =async (update) => {
      
      let invoiceTypeStatusIndex,
      invoiceTypeTemp;

      invoiceTypeTemp = invoiceTypesData.find(e => e._id == travelExpense.invoiceType);

      if(invoiceTypeTemp != undefined){
        invoiceTypeStatusIndex = invoiceTypeTemp.steps.findIndex(e => e._id == travelExpense.invoiceStatus);
      }
      if(invoiceTypeTemp == undefined || invoiceTypeStatusIndex == undefined){
        return;
      }
      if(update == "approve" && invoiceTypeStatusIndex < invoiceTypeTemp.steps.length - 1){
        onChangeTextOpenInvoice("invoiceStatus" ,invoiceTypeTemp.steps[invoiceTypeStatusIndex+1]._id);
      }else if(update == "reject" && invoiceTypeStatusIndex > 0 ){
        onChangeTextOpenInvoice("invoiceStatus" ,invoiceTypeTemp.steps[invoiceTypeStatusIndex -1]._id);
      }

    }
   
    const [subscriptionDuration, setSubscriptionDuration] = useState("annual");

    const api_url = process.env.NEXT_PUBLIC_API_URL

    const calculateTotalCost = async () =>{

        let totalCostTemp = 0,
        totalNetTemp = 0;
        travelExpense.childInvoices.map((invoiceItem)=>{
            totalCostTemp += parseFloat(invoiceItem.total);
            totalNetTemp += parseFloat(invoiceItem.totalNet);
        });
        totalCostTemp += parseFloat(travelExpense.compensation);

        //let costInGeneral = parseFloat(totalCostTemp);

        setTotalCost(totalCostTemp);
        setTotalNet(totalNetTemp);
    }
    const onChangeTextTravelExpense = async (key, value) =>{

        let travelExpenseTemp = travelExpense;
        
        travelExpenseTemp.travelExpense[key] = value;

        if(key == "country"){

          let countryAllowance = countryListAllowance.find(e => e.code == value);
          travelExpenseTemp.allowanceSmall = countryAllowance.small.toString();
          travelExpenseTemp.allowanceBig = countryAllowance.large.toString();
        }

        setTravelExpense({...travelExpenseTemp})
        calculateTravelExpenseCost(travelExpenseTemp)
    }
    const onChangeTextOpenInvoice = async (key, value) =>{

      let travelExpenseTemp = travelExpense;
      
      travelExpenseTemp[key] = value;

      setTravelExpense({...travelExpenseTemp})
      calculateTravelExpenseCost(travelExpenseTemp)
  }


    const removeInvoiceFromTravelExpense = async (elementIndex) =>{
      let travelExpenseTemp = travelExpense;
      travelExpenseTemp.invoices.splice(elementIndex, 1);
      setTravelExpense({...travelExpenseTemp})
    }

    let readOnlyAdminFields = true;
    if(session.user_data["role"]  == "ADMIN"){
      readOnlyAdminFields = false;
    }

    let travelExpenseManager = (
    <Box width={"90%"} >
    <Drawer
        isOpen={isTravelExpenseOpen}
        placement='right'
        onClose={onTravelExpenseClose}
        size="lg"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton onClick={onTravelExpenseClose} />
          <DrawerHeader style={{borderBottom: "1px solid #e2e8f0"}}>
            <Grid templateColumns='repeat(16, 1fr)' gap={3}>
              <GridItem>
                <HStack spacing='5px'> 
                  <div className='rekening-invoice-icon'>
                    <IoBriefcase fontSize={"35px"}></IoBriefcase>
                  </div>
                </HStack>
              </GridItem>
              <GridItem colSpan={8}>
                <Heading  as='h4' size='md'>
                  {travelExpense.travelExpense.shop}
                </Heading>
                <Box display='flex' alignItems='baseline'>
                  <Box
                    color='gray.500'
                    fontWeight='semibold'
                    letterSpacing='wide'
                    ml='0'
                    fontSize="initial"
                  >
                    {new Date(travelExpense.travelExpense.startDate).toLocaleDateString()}
                  </Box>
                </Box>
              </GridItem>
              <GridItem colSpan={6}>
                <Heading  as='h4' textAlign="right" size='md'>
                    {totalCost}€
                </Heading>
                <Box display='flex' alignItems='baseline'>
                  <Box
                    color='gray.500'
                    fontWeight='semibold'
                    letterSpacing='wide'
                    ml='0'
                    fontSize="initial"
                    textAlign="right"
                    width="100%"
                  >
                    {totalNet}€
                  </Box>
                </Box>
              </GridItem>
            </Grid>
          </DrawerHeader>

          <DrawerBody>
            <Grid mt="4" mb="2" templateColumns='repeat(8, 1fr)' gap={3}>
              <GridItem colSpan={6}>
                <Heading as="h3" size={"md"}>
                  Travel Expense
                </Heading>
              </GridItem>
              <GridItem colSpan={2}>
                {(newTravelExpense != true) &&
                <Button isLoading={travelExpenseUpdateLoading} onClick={deleteTravelExpense} size={"sm"} float="right" >
                  <IoTrash fontSize={"20px"} style={{marginRight: "5px"}}/>
                  Delete Travel Expense
                </Button>
                }
              </GridItem>
            </Grid>
            <Box mt="2">
              <Text fontSize={"smaller"} mb={"1"} fontWeight={"bold"}>Title</Text>
              <Input
                autoFocus={true}
                placeholder='Type here...'
                value={travelExpense.shop}
                onChange={evt => onChangeTextOpenInvoice(`shop`, evt.target.value)}
              />
            </Box>
            <Box mt="2">
              <Text fontSize={"smaller"} mb={"1"} fontWeight={"bold"}>Description</Text>
              <Textarea
                placeholder='Type here...'
                value={travelExpense.description}
                onChange={evt => onChangeTextOpenInvoice(`description`, evt.target.value)}
              />
            </Box>
            {(session != undefined && (session["user_data"]["role"] == "editor" || session["user_data"]["role"] == "ADMIN")) &&
            <Box mt="4">
              <Text fontSize={"smaller"} mb={"1"} fontWeight={"bold"}>Invoice Owner</Text>
              <Select
                disabled={readOnlyAdminFields}
                value={travelExpense.author}
                onChange={(e) =>{ onChangeTextOpenInvoice(`author`, e.target.value)}}
              >
                {userData.map((singleUser)=>{
                  return(
                    <option key={singleUser._id} value={singleUser._id}>{singleUser.username}</option>
                  )
                })}
              </Select>
            </Box>
            }
            {/*<Box mt="4">
              <Text fontSize={"smaller"} mb={"1"} fontWeight={"bold"}>Category</Text>
              <Select
                disabled={readOnlyAdminFields}
                value={travelExpense.category}
                onChange={(e) =>{ onChangeTextOpenInvoice(`category`, e.target.value)}}
              >
                {categoriesData.map((singleCategory)=>{
                  return(
                    <option value={singleCategory._id}>{singleCategory.title}</option>
                  )
                })}
              </Select>
              </Box>*/}
            <Divider mt="4"></Divider>
            <Heading mt="4" as="h3" size={"md"}>
              Duration & Transportation
            </Heading>
            <Grid templateColumns='repeat(4, 1fr)' gap={3}>
              <GridItem colSpan={2}>
                <Box mt="4">
                    <Text fontSize={"smaller"} mb={"1"} fontWeight={"bold"}>Start & End Date</Text>
                    <DatePicker
                      renderInput={renderCustomInput} 
                      value={selectedDayRange}
                      onChange={changeInvoiceDate}
                      colorPrimary="#F39F50" 
                    ></DatePicker>
                </Box>
              </GridItem>
              <GridItem colSpan={1}>
                <Box mt="4">
                    <Text fontSize={"smaller"} mb={"1"} fontWeight={"bold"}>Start Time</Text>
                    <input className="rekening-time-picker" type="time"></input>
                </Box>
              </GridItem>
              <GridItem colSpan={1}>
                <Box mt="4">
                    <Text fontSize={"smaller"} mb={"1"} fontWeight={"bold"}>End Time</Text>
                    <input className="rekening-time-picker" type="time"></input>
                </Box>
              </GridItem>
            </Grid>
            <Grid templateColumns='repeat(4, 1fr)' gap={3}>
              <GridItem colSpan={2}>
                <Box mt="4">
                  <Text fontSize={"smaller"} mb={"1"} fontWeight={"bold"}>Country of Destination</Text>
                  <Select
                    value={travelExpense.travelExpense.country}
                    onChange={evt => onChangeTextTravelExpense(`country`, evt.target.value)}
                  >
                    {countryListAllowance.map((country)=>{
                      return(
                        <option key={country.code} value={country.code}>{country.name}</option>
                      )
                    })}
                    </Select>
                </Box>
              </GridItem>
              <Box mt="4">
                <Text fontSize={"smaller"} mb={"1"} fontWeight={"bold"}>Arrival/Departure</Text>
                <Input readOnly={readOnlyAdminFields} onChange={evt => onChangeTextTravelExpense(`allowanceSmall`, evt.target.value)} type="number" value={travelExpense.allowanceSmall}></Input>
              </Box>
              <Box mt="4">
                <Text fontSize={"smaller"} mb={"1"} fontWeight={"bold"}>Full Day</Text>
                <Input readOnly={readOnlyAdminFields} onChange={evt => onChangeTextTravelExpense(`allowanceBig`, evt.target.value)} type="number"  value={travelExpense.allowanceBig}></Input>
              </Box>
            </Grid>
            <Grid templateColumns='repeat(2, 1fr)' gap={3}>
                <GridItem colSpan={1}>
                    <Box mt="4">
                        <Text fontSize={"smaller"} mb={"1"} fontWeight={"bold"}>Travel Start Point</Text>
                        <Input
                            placeholder='Type here...'
                            value={travelExpense.travelExpense.locationFrom}
                            onChange={evt => onChangeTextTravelExpense(`locationFrom`, evt.target.value)}
                        />
                    </Box>
                </GridItem>
                <GridItem colSpan={1}>
                    <Box mt="4">
                        <Text fontSize={"smaller"} mb={"1"} fontWeight={"bold"}>Travel Destination</Text>
                        <Input
                            placeholder='Type here...'
                            value={travelExpense.travelExpense.locationTo}
                            onChange={evt => onChangeTextTravelExpense(`locationTo`, evt.target.value)}
                        />
                    </Box>
                </GridItem>
            </Grid>
            <Grid templateColumns='repeat(2, 1fr)' gap={3}>
                <GridItem colSpan={1}>
                    <Box mt="4">
                        <Text fontSize={"smaller"} mb={"1"} fontWeight={"bold"}>Transportation</Text>
                        <Select value={travelExpense.travelExpense.transportation} onChange={evt => onChangeTextTravelExpense(`transportation`, evt.target.value)}>
                            <option value="train">Train</option>
                            <option value="plain">Plane</option>
                            <option value="own car">Own Car</option>
                            <option value="business car">Business Car</option>
                        </Select>
                    </Box>
                </GridItem>
                <GridItem colSpan={1}>
                    <Box mt="4">
                        <Text fontSize={"smaller"} mb={"1"} fontWeight={"bold"}>Travel Distance (km)</Text>
                        <Input
                            placeholder='Type here...'
                            type="number"
                            value={travelExpense.travelExpense.transportationDistance}
                            onChange={evt => onChangeTextTravelExpense(`transportationDistance`, evt.target.value)}
                        />
                    </Box>
                </GridItem>
            </Grid>

            <Divider mt="4"></Divider>
            <Heading mt="4" as="h3" size={"md"}>
              Expenses
            </Heading>

            <Grid templateColumns='repeat(3, 1fr)' gap={3}>
                <GridItem colSpan={1}>
                    <Box mt="4">
                        <Text fontSize={"smaller"} mb={"1"} fontWeight={"bold"}>Paid Breakfasts</Text>
                        <Input
                            placeholder='Type here...'
                            value={travelExpense.travelExpense.breakfasts}
                            type="number"
                            onChange={evt => onChangeTextTravelExpense(`breakfasts`, parseInt(evt.target.value))}
                        />
                    </Box>
                </GridItem>
                <GridItem colSpan={1}>
                    <Box mt="4">
                        <Text fontSize={"smaller"} mb={"1"} fontWeight={"bold"}>Paid Lunches</Text>
                        <Input
                            placeholder='Type here...'
                            value={travelExpense.travelExpense.lunches}
                            type="number"
                            onChange={evt => onChangeTextTravelExpense(`lunches`, parseInt(evt.target.value))}
                        />
                    </Box>
                </GridItem>
                <GridItem colSpan={1}>
                    <Box mt="4">
                        <Text fontSize={"smaller"} mb={"1"} fontWeight={"bold"}>Paid Dinners</Text>
                        <Input
                            placeholder='Type here...'
                            value={travelExpense.travelExpense.dinners}
                            type="number"
                            onChange={evt => onChangeTextTravelExpense(`dinners`, parseInt(evt.target.value))}
                        />
                    </Box>
                </GridItem>
            </Grid>




            <SimpleGrid style={{paddingBottom: "50px"}} mt="4" columns={1} spacing={3}>
            {travelExpense.childInvoices.map((item, index) => {
                
                const date= new Date(item.date).toLocaleDateString('de-DE');

                const dateArray = ["Januar", "Februar", "Maerz", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"];

                var invDate = new Date(item.date).toLocaleString();
                //const dateArray = new Date(item.date).getMonth();
                const dateNew = new Date(item.date).getMonth();
                const yearNew = new Date(item.date).getFullYear();
                let addDateHeader = undefined,
                addDateHeaderYear = "",
                addDateHeaderCost = undefined;
                
                let invoiceCurrency = item.currency;
                if(invoiceCurrency == "EUR" || invoiceCurrency == "" || invoiceCurrency == undefined){
                  invoiceCurrency = "€"
                }else if(invoiceCurrency == "USD"){
                  invoiceCurrency = "$"
                }else if(invoiceCurrency == "JPY"){
                  invoiceCurrency = "¥"
                }else if(invoiceCurrency == "GBP"){
                  invoiceCurrency = "£"
                }else if(invoiceCurrency == "INR"){
                  invoiceCurrency = "₹"
                }else if(invoiceCurrency == "CAD"){
                  invoiceCurrency = "$"
                }


                let iconData = categoriesData.find((e)=> e._id == item.category)

                let IconWeb = IoBriefcase;
                if(iconData != undefined){
                  IconWeb = iconData.iconWebElement;
                }

                let invoceSelectClassList = "rekening-invoice-item";

                return(
                  <Grid key={item._id} templateColumns='repeat(16, 1fr)' gap={2}>
                  <GridItem colSpan={15}>
                  <Box className={invoceSelectClassList} padding="3"  borderWidth='1px' borderRadius='lg' style={{transition: "all 0.2s"}}
                    _hover={{
                      cursor: "pointer",
                      backgroundColor: "#f4f4f4"
                    }}
                  >
                    
                    <Grid templateColumns='repeat(16, 1fr)' gap={2}>
                      <GridItem colSpan={1}>
                        <HStack spacing='5px'>
                        <div className='rekening-invoice-icon'>
                          <IconWeb style={{marginLeft: "2px", marginTop: "2px"}} fontSize={"30px"}></IconWeb>
                        </div>
                        </HStack>
                      </GridItem>
                      <GridItem colSpan={5}>
                        <Heading  as='h4' size='sm'>
                          {item.shop}
                        </Heading>
                        <Box display='flex' alignItems='baseline'>
                            {/*<Badge borderRadius='full' px='2' colorScheme='teal'>
                              Schritt {index}
                            </Badge>*/}
                            <Box
                              color='gray.500'
                              fontWeight='semibold'
                              letterSpacing='wide'
                              textTransform='uppercase'
                              ml='0'
                            >
                              {date}
                            </Box>
                          </Box>
                      </GridItem>
                      <GridItem colSpan={5}>
                        {item.scanAttached == "" &&
                          <Heading  as='h4' size='sm'>
                            Without Document
                          </Heading>
                        } 
                        {item.scanAttached != "" &&
                          <Heading  as='h4' size='sm'>
                            1 Document
                          </Heading>
                        } 
                        <Box display='flex' alignItems='baseline'>
                          {(item.status == undefined || item.status == "private") &&
                          <Badge borderRadius='full' px='2' mt="1" pr="28px" colorScheme={"red"}>
                            <IoLockClosed style={{float: "left", marginTop:"2px", marginRight: "3px"}}/>
                            <Text>In Progress</Text>
                          </Badge>
                          }
                          {(item.status == "requested") &&
                          <Badge borderRadius='full' px='2' mt="1" pr="28px" colorScheme={"orange"}>
                            <IoTimer style={{float: "left", marginTop:"3px", marginRight: "3px"}}/>
                            <Text>Waiting for refund</Text>
                          </Badge>
                          }
                          {(item.status == "refunded") &&
                          <Badge borderRadius='full' px='2' mt="1" pr="28px" colorScheme={"green"}>
                            <IoCheckmarkCircle style={{float: "left", marginTop:"2px", marginRight: "3px"}}/>
                            <Text>Refund done</Text>
                          </Badge>
                          }
                        </Box>
                      </GridItem>
                      <GridItem colSpan={5} textAlign="right">
                        <Heading  as='h4' size='sm'>
                          {item.total}{invoiceCurrency}
                        </Heading>
                        <Box display='flex' alignItems='baseline'>
                            {/*<Badge borderRadius='full' px='2' colorScheme='teal'>
                              Schritt {index}
                            </Badge>*/}
                            <Box
                              textAlign="right"
                              color='gray.500'
                              fontWeight='semibold'
                              letterSpacing='wide'
                              textTransform='uppercase'
                              ml='0'
                              width="100%"
                            >
                              {item.tax}{invoiceCurrency}
                            </Box>
                          </Box>
                      </GridItem>
                    </Grid>
                    </Box>
                    </GridItem>
                    <GridItem>
                      <Button height={"70px"} onClick={()=>removeInvoiceFromTravelExpense(index)}>
                        <IoTrash></IoTrash>
                      </Button>
                    </GridItem>
                  </Grid>
              )})}
          </SimpleGrid>
          <Box m="20">
          </Box>

          </DrawerBody>

          <DrawerFooter style={{borderTop: "1px solid #e2e8f0"}}>
            <InvoiceStatusElement typeName="Invoice" createInvoice={false} nextStepAllowed={true} invoiceTypesData={invoiceTypesData} invoiceType={travelExpense.invoiceType} invoiceStatus={travelExpense.invoiceStatus} changeValue={editTravelExpenseStatusUpdate}></InvoiceStatusElement>
        
            <Button mr={3} onClick={onTravelExpenseClose} >
              Cancel & Close
            </Button>
            {newTravelExpense != true &&
            <Button mr="3" isLoading={travelExpenseUpdateLoading} variant='outline' onClick={printTravelExpense}>
                <IoPrint/>
                <Text ml="2">
                  Print
                </Text>
            </Button>
            }
            {newTravelExpense != true &&
            <Button isLoading={travelExpenseUpdateLoading} onClick={updateTravelExpense} variant='outline'>
              <IoSave></IoSave>
              <Text ml="2">
                Save Changes
              </Text>
            </Button>
            }
            {newTravelExpense == true &&
            <Button isLoading={travelExpenseUpdateLoading} variant='outline' onClick={createNewTravelExpense}>
                <IoAddCircle/>
                <Text ml="2">
                  Submit Travel Expense
                </Text>
            </Button>
            }
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </Box>
    );
    
    return travelExpenseManager;
};

export default TravelExpensesManager;