import {
    Link as ChakraLink,
    Text,
    Input,
    Box,
    Badge,
    SimpleGrid,
    Button,
    Select,
    Heading,
    Avatar,
    Grid,
    GridItem,
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    Center,
    Divider,
    Spinner,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Progress,
    Tooltip,
    Alert,
    AlertIcon,
    HStack,
    Spacer,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Menu,
    VStack,
    useDisclosure,
    Flex,
    InputGroup,
  } from '@chakra-ui/react'



import Iframe from 'react-iframe'

import CurrencyInput from 'react-currency-input-field';


import DatePicker from "@hassanmojab/react-modern-calendar-datepicker";

import {IoPrint,IoGift, IoNotifications,IoRemoveCircle, IoChevronForwardOutline,IoChevronBack,  IoBriefcase, IoOpen, IoInformationCircle, IoCheckmarkDoneCircleSharp, IoCash,IoCheckmarkCircle, IoDownload ,IoCloudUpload,IoSave,IoDocumentAttachOutline , IoPersonAdd, IoAddCircle, IoShare,IoTrash, IoPersonCircleSharp, IoTimer, IoLockClosed, IoCloseCircle, IoAddCircleOutline} from 'react-icons/io5';

import React, { useEffect, useState, useRef,Component } from "react";
import AllIcons from '../constants/AllIcons';
import SelectCurrency from './SelectCurrency';
import SelectBudget from './SelectBudget';
import ChangeLogElement from './ChangeLogElement';
import InvoiceStatusElement from './InvoiceStatusElement';
import SelectInvoiceType from './SelectInvoiceType';
import TravelExpensesElement from './TravelExpenseElement';
import DeleteModal from './DeleteModal';



const InvoiceDetailDrawer = ({
  createInvoiceType,
  setBulkUploadCounterFinished,
  bulkUploadSettings,
  setBulkInsertData,
  bulkInsertData,
  uploadInProgress,
  categoriesData,
  userData,
  setUploadInProgress,
  editInvoiceReadonly,
  previewBackwards, 
  previewForwards, 
  editInvoiceReadonlyReason,
  loadInvoices,
  invoiceTypesData,
  loadBudgetSpending,
  loadSingleInvoices,
  scroller,
  rekeningData,
  setRekeningData,
  invoiceIsOpen,
  session,
  setChangedInvoices,
  invoiceOnClose,
  setSelectedInvoice,
  selectedInvoice,
  userNotification,
  createInvoice,
  setCreateInvoice,
  setIsDuplicateModalOpen,
  setDuplicateCurrentFile,
  setDuplicateUserDecision
}) => {

    const { isOpen: isCloseEditWithoutSave, onOpen: onCloseEditWithoutSaveOpen, onClose: onCloseEditWithoutSaveClose } = useDisclosure()

    const [selectedDay, setSelectedDay] = useState({
        year: new Date().getFullYear(),
        month: new Date().getMonth() +1,
        day: new Date().getUTCDate(),
      });
    const countryListAllowance = [
        {
          name: "Germany",
          small: 14,
          large: 28,
          code: "DE"
        },
        {
          name: "France",
          small: 29,
          large: 44,
          code: "FR"
        },
        {
          name: "Netherlands",
          small: 32,
          large: 47,
          code: "NL"
        },
        {
          name: "Poland",
          small: 20,
          large: 29,
          code: "PL"
        },
        {
          name: "Austria",
          small: 27,
          large: 40,
          code: "AT"
        },
        {
          name: "United Kingdome",
          small: 30,
          large: 45, 
          code: "UK"
        },
  
  
      ]  
    const emptyInvoice = {
        _id: undefined,
        status: "",
        invoiceKind: "invoice",
        category:"",
        uuid:"",
        shop:"",
        total:"0",
        tax:"0",
        totalNet:"0",
        mwst:"19",
        date:new Date().toUTCString(),
        items:[],
        scanAttached:"",
        scanAttachedMD5:"",
        location:{},
        paymentType:"",
        invoiceText:"",
        author: "",
        authorData:[{name:""}],
        currency: "EUR",
        changelog: [],
        travelExpense:{},
        taxPositions: [
          {
            taxRate: "19",
            value: "0",
            valueNet: "0"
          }
        ],
        invoiceType: undefined,
        invoiceStatus: undefined
      };

      const emptyTravelExpense = {
          locationFrom:"",
          locationTo:"",
          transportation:"train",
          transportationDistance:"",
          breakfasts:0,
          lunches:0,
          dinners:0,
          startDate:new Date().toUTCString(),
          endDate:new Date().toUTCString(),
          country: "DE",
          allowanceSmall: "14",
          allowanceBig: "28",
      };

  const [newInvoice, setNewInvoice] = useState(emptyInvoice);

  const [isDeleteOpen, setIsDeleteOpen] = useState(false);

  const [editInvoiceModified, setEditInvoiceModified] = useState(false);

  const [saveInvoiceLoading, setSaveInvoiceLoading] = useState(false);
  const [editInvoiceErrorArray, setEditInvoiceErrorArray] = useState([]);

  const api_url = process.env.NEXT_PUBLIC_API_URL
  const fileInputRef = useRef(null);

  
  React.useEffect(() => {
    let currentDate = new Date().toUTCString();
    let emptyInvoiceTemp = emptyInvoice;
    if(createInvoiceType == "travel-expense"){
      emptyInvoiceTemp.invoiceKind = "travel-expense";
      emptyInvoiceTemp.travelExpense = emptyTravelExpense;
      console.log("UPADET NEW INVOICE")
    }else{
      delete emptyInvoiceTemp.travelExpense;
    }
    setNewInvoice(emptyInvoiceTemp)

    setSelectedDay({
      year: new Date(currentDate).getFullYear(),
      month: new Date(currentDate).getMonth()+1,
      day: new Date(currentDate).getUTCDate(),
    })
  }, [invoiceIsOpen])

  React.useEffect(() => {
        if(bulkInsertData.length != 0){
            bulkInsert(bulkInsertData)
        }
  }, [bulkInsertData])

    const renderCustomInput = ({ ref }) => (
        <Input
          readOnly
          ref={ref} // necessary
          style={{
            width: "100%"
          }}
          placeholder="I'm a custom input"
          value={selectedDay ? `${selectedDay.day}.${selectedDay.month}.${selectedDay.year}` : ''}
        />
    )

    const closeInvoice = async ()=>{
        setCreateInvoice(true);
        setEditInvoiceErrorArray([]);
        setEditInvoiceModified(false);
        invoiceOnClose()
    }

  const deleteCurrentInvoice = async()=>{
    deleteInvoice(rekeningData[selectedInvoice]._id, false);
    setCreateInvoice(true);
    setSelectedInvoice(0);
    invoiceOnClose();
    setIsDeleteOpen(false);
    //calculateMonthlyCost()
    //setRekeningData([...rekeningDataTemp])
  }

  const deleteInvoice = async (id, bulkDelete) =>{

    const fetchData = await fetch(`${api_url}/invoices/${id}`, {
      method: "DELETE",
      headers: {
        'Authorization': `Bearer ${session.accessToken}`,
      },
    })
    let rekeningDataTemp = rekeningData.filter(e => e._id != id);
    if(!bulkDelete){
      setRekeningData(rekeningDataTemp);
    }
    setEditInvoiceModified(false)
    userNotification("Delete Successfull", "Your invoice has been successfully deleted", "success");
    setChangedInvoices([])
  }
  const checkInvoiceDuplicate = async (scanAttachedMD5) =>{

    const fetchData = await fetch(`${api_url}/invoices/checkDuplicate?scanAttachedMD5=${scanAttachedMD5}`, {
      credentials: "include",
      method: "GET",
      headers: {
        'Authorization': `Bearer ${session.accessToken}`,
        'Content-Type': 'application/json'
      }
    })

    const fetchDataJson = await fetchData.json();

    console.log("RESPONSE: ",fetchDataJson);

    if("statusCode" in fetchDataJson && fetchDataJson["message"] == "Internal server error"){
      return []
    }else{
      return fetchDataJson;
    }
  }

  const createNewInvoice = async (data, bulkUpload = false) =>{
    let body = data;


    if(data == undefined){
      return;
    }
    
    delete body.authorData;


    const fetchData = await fetch(`${api_url}/invoices`, {
      credentials: "include",
      method: "POST",
      headers: {
        'Authorization': `Bearer ${session.accessToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })

    const fetchDataJson = await fetchData.json();

    if("statusCode" in fetchDataJson && fetchDataJson["message"] == "Internal server error"){
      userNotification("Error creating the invoice", "error")
    }else if("statusCode" in fetchDataJson){
      console.log(fetchDataJson);
      userNotification("Creation Error", fetchDataJson["message"][0], "error");
      let errorTypes = []
      fetchDataJson["message"].map((singleError)=>{
        if(singleError == "invoiceType should not be empty"){
          errorTypes.push("invoiceType");
        }else if(singleError == "shop should not be empty"){
          errorTypes.push("shop");
        }else if(singleError == "taxPositions.0.value should not be empty" || singleError == "taxPositions.1.value should not be empty" || singleError == "taxPositions.2.value should not be empty" || singleError == "taxPositions.3.value should not be empty"){
          errorTypes.push("amount");
        }else if(singleError == "taxPositions.0.valueNet should not be empty" || singleError == "taxPositions.1.valueNet should not be empty" || singleError == "taxPositions.2.valueNet should not be empty" || singleError == "taxPositions.3.valueNet should not be empty"){
          errorTypes.push("amountNet");
        }else if(singleError == "taxPositions.0.taxRate should not be empty" || singleError == "taxPositions.1.taxRate should not be empty" || singleError == "taxPositions.2.taxRate should not be empty" || singleError == "taxPositions.3.taxRate should not be empty"){
          errorTypes.push("taxRate");
        }else if(singleError == "category should not be empty"){
          errorTypes.push("category");
        }else if(singleError == "travelExpense.locationFrom should not be empty"){
          errorTypes.push("locationFrom");
        }else if(singleError == "travelExpense.locationTo should not be empty"){
          errorTypes.push("locationTo");
        }else if(singleError == "travelExpense.transportationDistance should not be empty"){
          errorTypes.push("transportationDistance");
        }else if(singleError == "travelExpense.startDate must be a Date instance"){
          errorTypes.push("startDate");
        }else if(singleError == "travelExpense.endDate must be a Date instance"){
          errorTypes.push("endDate");
        }
      });
      setEditInvoiceErrorArray(errorTypes)
    }else{
      userNotification("New Invoice Created","Your new invoice has been successfully created","success");
      invoiceOnClose()
      setEditInvoiceErrorArray([]);
      await setNewInvoice(emptyInvoice);
      await setCreateInvoice(false);
    

      await setRekeningData([]);
      await loadInvoices()
      if(!bulkUpload  && "statusCode" in fetchDataJson == false){
        setChangedInvoices([fetchDataJson._id]);
        setTimeout(()=>{
          scroller.scrollTo(fetchDataJson._id, {
            duration: 500,
            delay: 0,
            smooth: true,
            offset: -100, // Scrolls to element + 50 pixels down the page
          })
        },200)
      }
    }
    return fetchDataJson;
  }


  const saveInvoiceData = async(openInvoiceData)=>{
    setEditInvoiceModified(true);
    if(createInvoice == true){
      setNewInvoice({...openInvoiceData})
    }else{

      const rekeningDataTemp = rekeningData;
      rekeningDataTemp[selectedInvoice] = openInvoiceData;
      setRekeningData([...rekeningDataTemp]);
    }
  }

  const updateInvoice = async (data) =>{
    let body = data

    if(data == undefined){
      return;
    }

    const fetchData = await fetch(`${api_url}/invoices/${data._id}`, {
      method: "PATCH",
      headers: {
        'Authorization': `Bearer ${session.accessToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })
    const fetchDataJson = await fetchData.json();
    if("statusCode" in fetchDataJson){
      userNotification("Update Error", fetchDataJson["message"][0], "error");

      let errorTypes = []
      fetchDataJson["message"].map((singleError)=>{
        if(singleError == "invoiceType should not be empty"){
          errorTypes.push("invoiceType");
        }else if(singleError == "shop should not be empty"){
          errorTypes.push("shop");
        }else if(singleError == "taxPositions.0.value should not be empty" || singleError == "taxPositions.1.value should not be empty" || singleError == "taxPositions.2.value should not be empty" || singleError == "taxPositions.3.value should not be empty"){
          errorTypes.push("amount");
        }else if(singleError == "taxPositions.0.valueNet should not be empty" || singleError == "taxPositions.1.valueNet should not be empty" || singleError == "taxPositions.2.valueNet should not be empty" || singleError == "taxPositions.3.valueNet should not be empty"){
          errorTypes.push("amountNet");
        }else if(singleError == "taxPositions.0.taxRate should not be empty" || singleError == "taxPositions.1.taxRate should not be empty" || singleError == "taxPositions.2.taxRate should not be empty" || singleError == "taxPositions.3.taxRate should not be empty"){
          errorTypes.push("taxRate");
        }else if(singleError == "category should not be empty"){
          errorTypes.push("category");
        }
      });
      setEditInvoiceErrorArray(errorTypes)
      return false;
    }else{
      userNotification("Update Successfull", "Your invoice has been successfully updated", "success");
      setChangedInvoices([fetchDataJson["_id"]]);
      closeInvoice()

      setEditInvoiceModified(false);
      setEditInvoiceErrorArray([]);

      await loadSingleInvoices(data._id)
      return true;
    }
    
  }
  
  const onChangeTextOpenInvoice = (key, val) => {
    
    let openInvoiceData = newInvoice;
    if(createInvoice == false){
      openInvoiceData = rekeningData[selectedInvoice];
    }
    if(key == "total"){
      val = val.replace(",",".");
    }
    if(key == "mwst"){
      if(val <= 0){
        val = 0;
      }if(val >= 100){
        val = 100;
      }
    }
    openInvoiceData[key] = val;

    if(key == "total" || key == "mwst"){
      let vat;
      let total = Number(openInvoiceData.total);
      vat = total * (1 - parseInt(openInvoiceData.mwst) / 100);
      vat = vat.toFixed(2);
      openInvoiceData.tax = vat;
    }
    if(key == "category"){
      loadBudgetSpending(val);
    }
    
    if(key == "invoiceType"){
      
      let invTypeTemp = invoiceTypesData.find(e=> e._id == val);
      
      openInvoiceData["invoiceStatus"] = invTypeTemp.steps[0]._id;
    }

    //SAVE
    saveInvoiceData(openInvoiceData);
  }


  const editInvoiceStatusUpdate = async (update) =>{
    let invoiceTypeStatusIndex,
    invoiceTypeTemp;

    invoiceTypeTemp = invoiceTypesData.find(e => e._id == editInvoice.invoiceType);

    if(invoiceTypeTemp != undefined){
      invoiceTypeStatusIndex = invoiceTypeTemp.steps.findIndex(e => e._id == editInvoice.invoiceStatus);
    }
    if(invoiceTypeTemp == undefined || invoiceTypeStatusIndex == undefined){
      return;
    }
    if(update == "approve" && invoiceTypeStatusIndex < invoiceTypeTemp.steps.length - 1){
      onChangeTextOpenInvoice("invoiceStatus" ,invoiceTypeTemp.steps[invoiceTypeStatusIndex+1]._id);
    }else if(update == "reject" && invoiceTypeStatusIndex > 0 ){
      onChangeTextOpenInvoice("invoiceStatus" ,invoiceTypeTemp.steps[invoiceTypeStatusIndex -1]._id);
    }
    saveInvoice()
  }
  const onChangeTextTravelExpense = async (key, value) =>{

      let invoiceDataTemp = editInvoice;
      
      invoiceDataTemp.travelExpense[key] = value;

      if(key == "country"){

        let countryAllowance = countryListAllowance.find(e => e.code == value);
        invoiceDataTemp.travelExpense["allowanceSmall"] = countryAllowance.small.toString();
        invoiceDataTemp.travelExpense["allowanceBig"] = countryAllowance.large.toString();
      }

      saveInvoiceData(invoiceDataTemp)
      //calculateTravelExpenseCost(travelExpenseTemp)
  }

  const onChangeTotalOpenInvoice = (key, val, ind)=>{


    let openInvoiceData = newInvoice;
    if(createInvoice == false){
      openInvoiceData = rekeningData[selectedInvoice];
    }


    let tax = parseFloat("0.0"),
    total = parseFloat("0.0"),
    totalNet = parseFloat("0.0");
    

    console.log("VAL: ", val)
    openInvoiceData.taxPositions[ind][key] = val;

    let indValue = openInvoiceData.taxPositions[ind]["value"],
    indValueNet = openInvoiceData.taxPositions[ind]["valueNet"],
    indTaxRate = openInvoiceData.taxPositions[ind]["taxRate"];


    openInvoiceData.taxPositions.map((singlePosition, i)=>{

      if(key == "value"){
        if(i == ind && indValue != "" && indTaxRate != ""){
          
          openInvoiceData.taxPositions[ind]["valueNet"] = (parseFloat(indValue) / (1 + parseInt(indTaxRate) / 100)).toFixed(2).toString();
      
          /*openInvoiceData.taxPositions[ind]["value"] = indValue;
          openInvoiceData.taxPositions[ind]["taxRate"] = (1 - (parseFloat(indValue) / parseFloat(indValueNet)) * 100).toFixed(0).toString();
          */
        }
      }

      if(key == "valueNet"){
        if(i == ind){
          indValueNet = val;
        }
      }

      if(key == "taxRate"){
        if(val <= 0){
          val = 0;
        }if(val >= 100){
          val = 100;
        }
        if(i == ind){
          indTaxRate = val;
        }
      }
      
      total += Number(singlePosition.value);
      /*totalNet += parseFloat(singlePosition.value) / (1 + parseInt(singlePosition.taxRate) / 100);
      tax += parseFloat(singlePosition.value) - (parseFloat(singlePosition.value) / (1 + parseInt(singlePosition.taxRate) / 100));
      */
      totalNet += parseFloat(singlePosition.valueNet);
      tax += (parseFloat(singlePosition.value) - parseFloat(singlePosition.valueNet));
    })

    

    openInvoiceData.total = total.toFixed(2).toString();
    openInvoiceData.tax = tax.toFixed(2).toString();
    openInvoiceData.totalNet = totalNet.toFixed(2).toString();
    //SAVE
    saveInvoiceData(openInvoiceData);
  }
  
  const editInvoiceAddTaxRate = async()=>{
    let openInvoiceData = newInvoice;
    if(createInvoice == false){
      openInvoiceData = rekeningData[selectedInvoice];
    }
    
    if(openInvoiceData.taxPositions == undefined){
      openInvoiceData.taxPositions = [];
      let tempDataPosition = {
        taxRate: openInvoiceData.mwst,
        value: openInvoiceData.total,
        valueNet: openInvoiceData.totalNet
      }
      openInvoiceData.taxPositions.push(tempDataPosition);
    }

    openInvoiceData.taxPositions.push({taxRate: "0", value: "", valueNet: ""})

    saveInvoiceData(openInvoiceData);
  }
  const editInvoiceRemoveTaxRate = async (index)=>{
    let openInvoiceData = newInvoice;
    if(createInvoice == false){
      openInvoiceData = rekeningData[selectedInvoice];
    }
    
    openInvoiceData.taxPositions.splice(index, 1);

    saveInvoiceData(openInvoiceData);

  }

  const removeImageFromInvoice = async () =>{
    let openInvoiceData = newInvoice;
    if(createInvoice == false){
      openInvoiceData = rekeningData[selectedInvoice];
    }
    
    openInvoiceData.scanAttached = "";
    openInvoiceData.scanAttachedMD5 = "";
    
    saveInvoiceData(openInvoiceData);


  }

  const changeInvoiceDate = async(newDate)=>{
    setSelectedDay(newDate);
    const invoiceDate = new Date(newDate.year, newDate.month -1, newDate.day);
    invoiceDate.setHours(12);
    let openInvoiceData = newInvoice;
    if(createInvoice == false){
      openInvoiceData = rekeningData[selectedInvoice];
    }
    openInvoiceData.date = invoiceDate.toUTCString();
    saveInvoiceData(openInvoiceData);
  }
  const refund = async (status) =>{
    let openInvoiceData = newInvoice;
    if(createInvoice == false){
      openInvoiceData = rekeningData[selectedInvoice];
    }
    openInvoiceData.status = status;

    saveInvoiceData(openInvoiceData);
  }


  const saveInvoice = async()=>{
    setSaveInvoiceLoading(true);
    let openInvoiceData = newInvoice;
    if(createInvoice == false && !editInvoiceReadonly){
      openInvoiceData = rekeningData[selectedInvoice];
      await updateInvoice(openInvoiceData)
    }else if(createInvoice == true){
      await createNewInvoice(openInvoiceData)
    }else{
      userNotification("Saving not possible",editInvoiceReadonlyReason,"error");
    }
    //setRekeningDataOriginal([...rekeningData])

    setSaveInvoiceLoading(false)
  }

  const removeProduct = async(index)=>{
    let openInvoiceData = newInvoice;
    if(createInvoice == false){
      openInvoiceData = rekeningData[selectedInvoice];
    }
    openInvoiceData.items.splice(index, 1);
    saveInvoiceData(openInvoiceData);
  }



  //Bulk Upload:

  const uploadFile = async(data, returnOnlyData = false, aiDisabled = false) =>{
    
    console.log("AI DISABLED");
    if(returnOnlyData == false){
      setUploadInProgress(true)
    }
    var formData = new FormData();
    formData.append('file', data)
    const fetchData = await fetch(`${api_url}/files/upload/?disableAi=${aiDisabled}`, {
      headers: {
        'Authorization': `Bearer ${session.accessToken}`,
      },
      credentials: "include",
      method: "Post",
      body: formData
    })
    const response = await fetchData.json();
    console.log(response);
    if(response.statusCode == 400 || response.statusCode == 500){
      alert("Only PDF, PNG & JPG are allowed");
      setUploadInProgress(false);
      return;
    }
    if(returnOnlyData == true){
      return response;
    }
    if("data" in response){
      let invoiceData = rekeningData[selectedInvoice];
      invoiceData.scanAttached = response.imageUrl;
      saveInvoiceData(invoiceData);
    }else{
      onChangeTextOpenInvoice("scanAttached",response.imageUrl);
      onChangeTextOpenInvoice("scanAttachedMD5",response.scanAttachedMD5);
    }
    setUploadInProgress(false)
    
  }

  const bulkInsert = async (acceptedFiles) => {
    let counter = 0;
    let newInvoiceIds = [];
    setBulkUploadCounterFinished(0);
    for (const file of acceptedFiles) {
        console.log("FILE:", file);
        let uploadData = await uploadFile(file, true);
        if ("data" in uploadData) {
            console.log("Upload Data:", uploadData);
            let checkDuplicate = await checkInvoiceDuplicate(uploadData.scanAttachedMD5);
            
            console.log("DUPLICATE CHEHCK", checkDuplicate);

            if (checkDuplicate != undefined && checkDuplicate.length != 0) {
                console.log("DUPLICATE", checkDuplicate)
                // Show the modal and wait for the user's decision
                setDuplicateCurrentFile(file);
                setIsDuplicateModalOpen(true);

                // Wait for user's decision
                const decision = await new Promise((resolve) => {
                    const handleDecision = (decision) => {
                        setIsDuplicateModalOpen(false);
                        resolve(decision);
                    };
                    setDuplicateUserDecision(() => handleDecision);
                });

                if (decision === 'skip') {
                    counter++;
                    setBulkUploadCounterFinished(counter);
                    continue;
                }
            }
            
            uploadData.data.category = bulkUploadSettings.category;
            uploadData.data.invoiceType = bulkUploadSettings.invoiceType;
            uploadData.data.invoiceStatus = invoiceTypesData.find(e => e._id == bulkUploadSettings.invoiceType).steps[0]._id;
            
            let creatInvoice = await createNewInvoice(uploadData.data, true);

            newInvoiceIds.push(creatInvoice["_id"]);
            counter++;
            setBulkUploadCounterFinished(counter);
        } else {
            counter++;
            setBulkUploadCounterFinished(counter);
            userNotification("Upload Error", "One document could not be imported", "error");
        }
    }
    setChangedInvoices(newInvoiceIds);
    setBulkInsertData([]);
  }


/*
  const bulkInsert = async (acceptedFiles) =>{
    let counter = 0;
    let newInvoiceIds = []
    setBulkUploadCounterFinished(0);
    for (const file of acceptedFiles) {
      console.log("FILE:", file);
        let uploadData = await uploadFile(file, true);
        if("data" in uploadData){
          console.log("Upload DAta:", uploadData);
          let checkDuplicate = await checkInvoiceDuplicate(uploadData.scanAttachedMD5);
          
          if(checkDuplicate != undefined && checkDuplicate.length != 0){
            //show 2 buttons in Frontend and wait fpr decision
          }
          
          uploadData.data.category = bulkUploadSettings.category;
          uploadData.data.invoiceType = bulkUploadSettings.invoiceType;
          uploadData.data.invoiceStatus = invoiceTypesData.find(e => e._id == bulkUploadSettings.invoiceType).steps[0]._id;
          
          let creatInvoice = await createNewInvoice(uploadData.data, true);

          newInvoiceIds.push(creatInvoice["_id"])
          counter = counter + 1;
          setBulkUploadCounterFinished(counter);
        }else{
          counter = counter + 1;
          setBulkUploadCounterFinished(counter);
          userNotification("Upload Error","One document could not be imported", "error")
        }
    }
    setChangedInvoices(newInvoiceIds);
    setBulkInsertData([])
  }*/

  const printTravelExpense = (id)=>{
    window.open("/travelExpensePrint/"+id, "_blank").focus()
  }


  let invoiceTypeNextStepAllowed = false;
  let invoiceTypeBackStepAllowed = false;


    //Data for drawer
    let editInvoice = newInvoice;
    if((createInvoice == true || rekeningData.length == 0)){
        editInvoice = newInvoice;
    }else{
        editInvoice = rekeningData[selectedInvoice];
    }

    try {
        const invoiceTypeTemp = invoiceTypesData.find(e => e._id == editInvoice.invoiceType);
        if(invoiceTypeTemp != undefined){
          const invoiceTypeStatus = invoiceTypeTemp.steps.find(e => e._id == editInvoice.invoiceStatus);
          const invoiceTypeStatusIndex = invoiceTypeTemp.steps.findIndex(e => e._id == editInvoice.invoiceStatus);
    
          let invoiceTypeStepApproval = invoiceTypeStatus.approval.some(item => session.user_data.groups.includes(item));
          invoiceTypeNextStepAllowed = (invoiceTypeStepApproval && invoiceTypeStatusIndex < invoiceTypeTemp.steps.length -1)
          invoiceTypeBackStepAllowed = (invoiceTypeStepApproval && invoiceTypeStatusIndex > 0);
        }else{
          invoiceTypeNextStepAllowed = true;
        }
      } catch (error) {
        console.log(error)
      }

      let currencyInputConfig = {"locale":"de","currency":editInvoice.currency};
    
  const modalContent = invoiceIsOpen ? (
    <div>
    <Drawer
    isOpen={invoiceIsOpen}
    placement='right'
    size="lg"
    onClose={()=>{
      console.log("editInvoiceModified",editInvoiceModified)
      console.log("invoiceTypeNextStepAllowed",invoiceTypeNextStepAllowed)
      console.log("newInvoice",newInvoice)
      if(editInvoiceModified == true && invoiceTypeNextStepAllowed && !createInvoice){
        onCloseEditWithoutSaveOpen()
      }else{
        closeInvoice()
      }
    }}
  >
    <DrawerOverlay style={{zIndex: 10}} />
    <DrawerContent>
      <DrawerCloseButton />
      <DrawerHeader  style={{borderBottom: "1px solid #e2e8f0"}}>
        <Grid templateColumns='repeat(16, 1fr)' gap={3}>
          <GridItem>
            <HStack spacing='5px'> 
            {( "authorData" in editInvoice && editInvoice.authorData.length != 0) &&
              <Avatar marginRight={2} borderRadius={10} height={45} width={45} className='rekening-invoice-avatar' name={editInvoice["authorData"][0]["username"]} />
            }
            {("authorData" in editInvoice && editInvoice.authorData.length == 0) &&
              <Avatar marginRight={2} borderRadius={10} height={45} width={45} className='rekening-invoice-avatar' name={""} />
            }
              <div className='rekening-invoice-icon'>
                <IoBriefcase fontSize={"35px"}></IoBriefcase>
              </div>
            </HStack>
          </GridItem>
          <GridItem colSpan={8}>
            <Heading  as='h4' size='md'>
              {editInvoice.shop}
            </Heading>
            <Box display='flex' alignItems='baseline'>
              <Box
                color='gray.500'
                fontWeight='semibold'
                letterSpacing='wide'
                ml='0'
                fontSize="initial"
              >
                {new Date(editInvoice.date).toLocaleDateString()}
              </Box>
            </Box>
          </GridItem>
          <GridItem colSpan={6}>
            <Heading  as='h4' textAlign="right" size='md'>
              {editInvoice.total} {editInvoice.currency}
            </Heading>
            <Box display='flex' alignItems='baseline'>
              <Box
                color='gray.500'
                fontWeight='semibold'
                letterSpacing='wide'
                ml='0'
                fontSize="initial"
                textAlign="right"
                width="100%"
              >
              {editInvoice.totalNet} ({editInvoice.tax}) {editInvoice.currency}
              </Box>
            </Box>
          </GridItem>
        </Grid>
      </DrawerHeader>

      <DrawerBody >
        {invoiceTypeNextStepAllowed == false && createInvoice == false &&
        <Alert mt="5" status='warning'>
          <AlertIcon />
          {"You have no rights to edit this invoice in this status."}
        </Alert>
        }
        <Grid mt="6" mb="2" templateColumns='repeat(8, 1fr)' gap={3}>
          <GridItem colSpan={6}>
              {editInvoice.invoiceKind == "travel-expense" &&
              <Heading as="h3" size={"md"}>
                Travel Expense
              </Heading>
              }
              {editInvoice.invoiceKind == "invoice" || editInvoice.invoiceKind == undefined &&
              <Heading as="h3" size={"md"}>
                Invoice
              </Heading>
              }
          </GridItem>
          <GridItem colSpan={2}>
            {(createInvoice == false && invoiceTypeNextStepAllowed == true) &&
            <Button size={"sm"} float="right" onClick={()=>setIsDeleteOpen(true)}>
              <IoTrash fontSize={"20px"} style={{marginRight: "5px"}}/>
              {editInvoice.invoiceKind == "invoice" && <p>Delete Invoice</p> }
              {editInvoice.invoiceKind == "travel-expense" && <p>Delete Travel Expense</p> } 
            </Button>
            }
            <DeleteModal 
              isOpen={isDeleteOpen}
              onCancel={()=>setIsDeleteOpen(false)}
              onConfirm={deleteCurrentInvoice}
              headerMessage={"Delete Invoice"}
              bodyMessage={"Are you sure you want to delete this invoice? This can't be undone!"}
            ></DeleteModal>
          </GridItem>
        </Grid>
        <Box mt="2">
          <Text fontSize={"smaller"} mb={"1"} fontWeight={"bold"}>Name</Text>
          <Input
            autoFocus={createInvoice}
            placeholder='Type here...'
            value={editInvoice.shop}
            disabled={!invoiceTypeNextStepAllowed}
            onChange={evt => onChangeTextOpenInvoice(`shop`, evt.target.value)}
            isInvalid={editInvoiceErrorArray.includes("shop")}
          />
        </Box> 
        <SelectInvoiceType 
          changeValue={onChangeTextOpenInvoice}
          value={editInvoice.invoiceType}
          disabled={(editInvoice.invoiceType != undefined && editInvoice.invoiceType != "" && createInvoice == false)}
          isInvalid={editInvoiceErrorArray.includes("invoiceType")}
          invoiceTypesData={invoiceTypesData}
          ></SelectInvoiceType>

        <SelectBudget 
          changeValue={onChangeTextOpenInvoice}
          disabled={!invoiceTypeNextStepAllowed}
          value={editInvoice.category}
          categoriesData={categoriesData}
          isInvalid={editInvoiceErrorArray.includes("category")}
          session={session}
          api_url={api_url}
          invoiceDate={editInvoice.date} />
        
        <Grid templateColumns='repeat(2, 1fr)' gap={3}>
          <GridItem>
            <Box mt="4">
              <Text fontSize={"smaller"} mb={"1"} fontWeight={"bold"}>Date</Text>
              {invoiceTypeNextStepAllowed &&
                <DatePicker
                  renderInput={renderCustomInput} 
                  value={selectedDay}
                  onChange={changeInvoiceDate}
                  colorPrimary="#F39F50" 
                ></DatePicker>
              }
              {!invoiceTypeNextStepAllowed &&
                <Input
                placeholder='Type here...'
                value={selectedDay.day+"."+selectedDay.month+"."+selectedDay.year}
                disabled={!invoiceTypeNextStepAllowed}
                />
              }
            </Box>
          </GridItem>
          <SelectCurrency value={editInvoice.currency} disabled={!invoiceTypeNextStepAllowed} changeValue={onChangeTextOpenInvoice} />
        </Grid>
        
        {/*(session != undefined && (session["user_data"]["role"] == "ADMIN" || session["rights"].includes("manage-invoice-owner"))) &&
        <Box mt="4">
          <Text fontSize={"smaller"} mb={"1"} fontWeight={"bold"}>Creator</Text>
          <Select
            disabled={!invoiceTypeNextStepAllowed}
            value={editInvoice.author}
            onChange={(e) =>{ onChangeTextOpenInvoice(`author`, e.target.value)}}
          >
            <option value="" disabled selected>Please select</option>
            {userData.map((singleUser)=>{
              return(
                <option key={singleUser._id+"-list-user-inv"} value={singleUser._id}>{singleUser.username}</option>
              )
            })}
          </Select>
        </Box>
          */}
        {"travelExpense" in editInvoice &&
          <TravelExpensesElement invoiceTypeNextStepAllowed={invoiceTypeNextStepAllowed} editInvoiceErrorArray={editInvoiceErrorArray} countryListAllowance={countryListAllowance} travelExpense={editInvoice.travelExpense} readOnlyAdminFields={false} changeText={onChangeTextTravelExpense}></TravelExpensesElement>
        }
        <Divider mt="5"></Divider>
        <Grid mt="5" mb="4" templateColumns='repeat(8, 1fr)' gap={3}>
          <GridItem colSpan={6}>
            { editInvoice.invoiceKind != "travel-expense" &&
            <Heading as="h3" size={"md"}>
                Total Amount              
            </Heading>
            }
            { editInvoice.invoiceKind == "travel-expense" &&
            <Heading as="h3" size={"md"}>
                Your Compensation              
            </Heading>
            }
          </GridItem>
          <GridItem colSpan={2}>
          </GridItem>
        </Grid>
        {(editInvoice.taxPositions == undefined || editInvoice.taxPositions.length == 0) &&
        <Box mt="2">
          <Grid templateColumns='repeat(2, 1fr)' gap={3}>
            <GridItem colSpan={1} >
              <Text fontSize={"smaller"} mb={"1"} fontWeight={"bold"}>Amount in {editInvoice.currency}</Text>
              <Input
                placeholder='0'
                value={editInvoice.total}
                onChange={evt => onChangeTotalOpenInvoice(`value`, evt.target.value, 0)}
                type="number"
                min="0"
                disabled={!invoiceTypeNextStepAllowed}
                isInvalid={editInvoiceErrorArray.includes("amount")}
              />
            </GridItem>    
            <GridItem colSpan={1} >
              <Text fontSize={"smaller"} mb={"1"} fontWeight={"bold"}>Vat. in %</Text>
              <Input
                placeholder='Type here...'
                value={editInvoice.mwst}
                onChange={evt => onChangeTotalOpenInvoice(`taxRate`, evt.target.value, 0)}
                type="number"
                min="0"
                max="100"
                disabled={!invoiceTypeNextStepAllowed}
              />
            </GridItem> 
          </Grid>
        </Box>
        }
        {(editInvoice.taxPositions != undefined  && editInvoice.taxPositions.length != 0) &&
        <Box>
          {editInvoice.taxPositions.map((singleTaxPosition, index)=>{
            let addRemoveButton = false;
            if(index > 0){
              addRemoveButton = true;
            }

            return(
            <Grid key={index+"-tax"} mt="2" templateColumns='repeat(16, 1fr)' gap={3}>
              <GridItem colSpan={5} >
                <Text fontSize={"smaller"} mb={"1"} fontWeight={"bold"}>Gross Amount (incl. Tax)</Text>
                  <CurrencyInput
                    className={"like-chakra-input" + (editInvoiceErrorArray.includes("amount") ? " invalid" : "")}
                    placeholder="Please enter a number"
                    defaultValue={singleTaxPosition.value}
                    decimalsLimit={2}
                    allowNegativeValue={false}
                    intlConfig={currencyInputConfig}
                    step={1}
                    onValueChange={(value, name, values) => onChangeTotalOpenInvoice(`value`, values.float, index)}
                  />
              </GridItem>    
              <GridItem colSpan={5} >
                <Text fontSize={"smaller"} mb={"1"} fontWeight={"bold"}>Net Amount (without Tax)</Text>
                  <CurrencyInput
                    className={"like-chakra-input" + (editInvoiceErrorArray.includes("amountNet") ? " invalid" : "")}
                    placeholder="Please enter a number"
                    defaultValue={singleTaxPosition.valueNet}
                    decimalsLimit={2}
                    allowNegativeValue={false}
                    intlConfig={currencyInputConfig}
                    step={1}
                    onValueChange={(value, name, values) => onChangeTotalOpenInvoice(`valueNet`, values.float, index)}
                    disabled={(!invoiceTypeNextStepAllowed || editInvoice.invoiceKind == "travel-expense")}
                  />
              </GridItem>    
              <GridItem colSpan={2} >
                <Text fontSize={"smaller"} mb={"1"} fontWeight={"bold"}>Vat. in %</Text>
                  <CurrencyInput
                    className={"like-chakra-input" + (editInvoiceErrorArray.includes("taxRate") ? " invalid" : "")}
                    placeholder="Please enter a number"
                    defaultValue={singleTaxPosition.taxRate}
                    decimalsLimit={2}
                    allowNegativeValue={false}
                    intlConfig={currencyInputConfig}
                    step={1}
                    maxLength={2}
                    onValueChange={(value, name, values) => onChangeTotalOpenInvoice(`taxRate`, values.float, index)}
                    disabled={(!invoiceTypeNextStepAllowed || editInvoice.invoiceKind == "travel-expense")}
                    suffix={" %"}
                  />
              </GridItem> 

              {(addRemoveButton == false && invoiceTypeNextStepAllowed == true) && (editInvoice.invoiceKind != "travel-expense")  &&
              <GridItem colSpan={4} >
                <Button mt="6" isFullWidth float="right" onClick={editInvoiceAddTaxRate}>
                  <IoAddCircle fontSize={"20px"} style={{marginRight: "5px"}}/>
                    Tax Rates
                </Button>
              </GridItem>
              }
              {addRemoveButton && invoiceTypeNextStepAllowed == true &&
              <GridItem colSpan={4} >
                <Button mt="6" isFullWidth float="right" onClick={e => editInvoiceRemoveTaxRate(index)}>
                  <IoRemoveCircle fontSize={"20px"} style={{marginRight: "5px"}}/>
                    Remove
                </Button>
              </GridItem>
              }
            </Grid>
            )
          })}
        </Box>
        }
        

        <Divider mt="6"></Divider>
        {editInvoice.invoiceKind == "invoice" &&
        <Grid mt="6" mb="2" templateColumns='repeat(8, 1fr)' gap={3}>
            <GridItem colSpan={6}>
              <Heading as="h3" size={"md"}>
                Invoice Scan
              </Heading>
            </GridItem>
            <GridItem colSpan={2}>
              {invoiceTypeNextStepAllowed == true &&
              <Button size={"sm"} isLoading={uploadInProgress} float="right" onClick={() => fileInputRef.current && fileInputRef.current.click()} >
                <IoCloudUpload fontSize={"20px"} style={{marginRight: "5px"}}/>
                Upload Invoice
              </Button>
              }
              <input ref={fileInputRef} style={{display: "none"}} type="file" id="file-upload" onChange={(e) => uploadFile(e.target.files[0], false, true)}/>
            </GridItem>
          </Grid>
          }
          {editInvoice.scanAttached != "" &&
          <Box 
            borderWidth={1}
            borderRadius='lg' 
            _hover={{
              cursor: "pointer",
              backgroundColor: "#f4f4f4"
            }}
            style={{transition: "all 0.2s"}} 
            >
              <Flex>
              <IoDocumentAttachOutline style={{fontSize: "2em", marginLeft:"2%", marginTop:"6px"}}></IoDocumentAttachOutline>
              <Spacer />
              <Box mt="2" mr="2" mb="2" float={"right"}>
                <Button  onClick={()=>removeImageFromInvoice()} mr="4" variant="ghost" colorScheme={"grey"} size={"sm"}>
                  <IoTrash fontSize={"20px"} style={{marginRight: "5px"}}/>
                  Delete
                </Button>
                <Button size={"sm"} onClick={()=>window.open(editInvoice.scanAttached, '_blank')}>
                  <IoOpen fontSize={"20px"} style={{marginRight: "5px"}}/>
                  Preview
                </Button>
              </Box>
              </Flex>
          </Box>
        }
        {editInvoice.scanAttached == "" && editInvoice.invoiceKind == "invoice" &&
          <Text fontSize={"smaller"} mb={"1"} fontWeight={"bold"}>No document attached</Text>
        }
        {createInvoice == false &&
          <ChangeLogElement userData={userData} value={editInvoice.changelog}></ChangeLogElement>
        }

      </DrawerBody>

      <DrawerFooter style={{borderTop: "1px solid #e2e8f0"}}>
        <SimpleGrid  width={"100%"} columns={1}>
        <InvoiceStatusElement typeName="Invoice" createInvoice={createInvoice} nextStepAllowed={invoiceTypeNextStepAllowed} invoiceTypesData={invoiceTypesData} invoiceType={editInvoice.invoiceType} invoiceStatus={editInvoice.invoiceStatus} changeValue={editInvoiceStatusUpdate}></InvoiceStatusElement>
        <Flex mt={5}>
        {createInvoice != true &&
        <Button mr={3} onClick={previewBackwards}>
          <IoChevronBack/>
        </Button>
        }
        {createInvoice != true &&
        <Button mr={3} onClick={previewForwards}>
          <IoChevronForwardOutline/>
        </Button>
        }
        {createInvoice != true && editInvoice.invoiceKind == "travel-expense" &&
          <Button mr="3" variant='outline' onClick={()=>printTravelExpense(editInvoice._id)}>
              <IoPrint/>
              <Text ml="2">
                Print
              </Text>
          </Button>
        }
        <Spacer></Spacer>
        <Button mr={3} onClick={closeInvoice}>
          Cancel & Close
        </Button>
        {(createInvoice == false && invoiceTypeNextStepAllowed == true) &&
        <Button onClick={saveInvoice} isLoading={saveInvoiceLoading} disabled={!editInvoiceModified} variant='outline'>
          <IoSave></IoSave>
          <Text ml="2">
            Save Changes
          </Text>
        </Button>
        }
        {createInvoice == true &&
        <Button onClick={saveInvoice} isLoading={saveInvoiceLoading} variant='outline'>
          <IoSave></IoSave>
          {editInvoice.invoiceKind == "travel-expense" &&
            <Text ml="2">
              Add Travel Expense
            </Text>
          }
          {editInvoice.invoiceKind == "invoice" &&
            <Text ml="2">
              Add Invoice
            </Text>
          }
        </Button>
        }
        </Flex>
        </SimpleGrid>
      </DrawerFooter>
    </DrawerContent>
    {invoiceIsOpen && editInvoice.scanAttached != undefined && editInvoice.scanAttached != "" && editInvoice.scanAttached.split('.').pop() == "pdf" &&
    <Iframe scrolling='yes' className="pdf-viewer" url={editInvoice.scanAttached}/>
    } 
    {invoiceIsOpen && editInvoice.scanAttached != undefined && editInvoice.scanAttached != "" && editInvoice.scanAttached.split('.').pop() != "pdf" &&
    <div className="pdf-viewer image" style={{backgroundImage: "url("+editInvoice.scanAttached+")"}}/>
    } 
  </Drawer>


      
    <Modal isOpen={isCloseEditWithoutSave} onClose={onCloseEditWithoutSaveClose}>
        <ModalOverlay/>
        <ModalContent>
          <ModalHeader>Close Without Saving</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Are you sure closing the editor without saving your changes?</Text>
          </ModalBody>
          <ModalFooter>
            <Button onClick={()=>{onCloseEditWithoutSaveClose(); closeInvoice()}} mr={3}>Discard & Close</Button>
            <Button onClick={()=>{saveInvoice(); onCloseEditWithoutSaveClose();}} mr={3}>
              <IoCheckmarkDoneCircleSharp style={{fontSize: "1.2em", marginRight:"5px"}}></IoCheckmarkDoneCircleSharp>
              Save & Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

  </div>
  ) : null;
  
  return modalContent
};

export default InvoiceDetailDrawer;