
import {IoCloud,IoGift, IoTerminal,IoServer, IoCalendar,IoBusiness,IoBuild, IoBeaker, IoBarbell, IoLeaf, IoBag, IoText, IoHappy,IoShapes, IoMedkit, IoCar, IoBicycle, IoSchool, IoShirt, IoPint, IoPizza, IoAtCircle, IoAirplane, IoHome, IoSubway, IoColorPalette, IoTv, IoFitness, IoPaw, IoMusicalNotes, IoSettings, IoExit, IoChevronForward, IoBriefcase, IoOpen, IoInformationCircle, IoCheckmarkDoneCircleSharp, IoCash,IoCheckmarkCircle, IoDownload ,IoCloudUpload,IoSave,IoDocumentAttachOutline , IoPersonAdd, IoAddCircle, IoShare,IoTrash, IoPersonCircleSharp, IoTimer, IoLockClosed, IoCloseCircle} from 'react-icons/io5';

const AllIcons = [
  {
    icon: "briefcase",
    iconWeb: "IoBriefcase",
    iconWebElement: IoBriefcase,
    title: "Work"
  },{
    icon: "happy",
    iconWeb: "IoHappy",
    iconWebElement: IoHappy,
    title: "Private"
  },{
    icon: "car" ,
    iconWeb: "IoCar",
    iconWebElement: IoCar ,
    title: "Car"
  },
  {
    icon: "bicycle",
    iconWeb: "IoBicycle",
    iconWebElement: IoBicycle,
    title: "Freizeit"
  },
  {
    icon: "school",
    iconWeb: "IoSchool",
    iconWebElement: IoSchool,
    title: "Education"
  },
  {
    icon: "shirt",
    iconWeb: "IoShirt",
    iconWebElement: IoShirt,
    title: "Clothing"
  },
  {
    icon: "pint",
    iconWeb: "IoPint",
    iconWebElement: IoPint,
    title: "Drinks"
  },
  {
    icon: "pizza",
    iconWeb: "IoPizza",
    iconWebElement: IoPizza,
    title: "Food"
  },
  {
    icon: "at",
    iconWeb: "IoAtCircle",
    iconWebElement: IoAtCircle,
    title: "Digital"
  },
  {
    icon: "medkit",
    iconWeb: "IoMedkit",
    iconWebElement: IoMedkit,
    title: "Medicine"
  },
  {
    icon: "airplane",
    iconWeb: "IoAirplane",
    iconWebElement: IoAirplane,
    title: "Travel"
  },
  {
    icon: "home",
    iconWeb: "IoHome",
    iconWebElement: IoHome,
    title: "Office"
  },
  {
    icon: "subway",
    iconWeb: "IoSubway",
    iconWebElement: IoSubway,
    title: "Local Travel"
  },
  {
    icon: "color-wand",
    iconWeb: "IoColorPalette",
    iconWebElement: IoColorPalette,
    title: "Art"
  },
  {
    icon: "tv",
    iconWeb: "IoTv",
    iconWebElement: IoTv,
    title: "Elektronics"
  },
  {
    icon: "fitness",
    iconWeb: "IoFitness",
    iconWebElement: IoFitness,
    title: "Fitness"
  },
  {
    icon: "paw",
    iconWeb: "IoPaw",
    iconWebElement: IoPaw,
    title: "Pets"
  },
  {
    icon: "musical-notes",
    iconWeb: "IoMusicalNotes",
    iconWebElement: IoMusicalNotes,
    title: "Music"
  },
  { 
    icon: "planet",
    iconWeb: "IoShapes",
    iconWebElement: IoShapes,
    title: "Others"
  },
  { 
    icon: "leaf",
    iconWeb: "IoLeaf",
    iconWebElement: IoLeaf,
    title: ""
  },
  { 
    icon: "bag",
    iconWeb: "IoBag",
    iconWebElement: IoBag,
    title: ""
  },
  { 
    icon: "barbell",
    iconWeb: "IoBarbell",
    iconWebElement: IoBarbell,
    title: ""
  },
  { 
    icon: "beaker",
    iconWeb: "IoBeaker",
    iconWebElement: IoBeaker,
    title: ""
  },
  { 
    icon: "build",
    iconWeb: "IoBuild",
    iconWebElement: IoBuild,
    title: ""
  },
  { 
    icon: "business",
    iconWeb: "IoBusiness",
    iconWebElement: IoBusiness,
    title: ""
  },
  { 
    icon: "calendar",
    iconWeb: "IoCalendar",
    iconWebElement: IoCalendar,
    title: ""
  },
  { 
    icon: "terminal",
    iconWeb: "IoTerminal",
    iconWebElement: IoTerminal,
    title: ""
  },
  { 
    icon: "cloud",
    iconWeb: "IoCloud",
    iconWebElement: IoCloud,
    title: ""
  },
  { 
    icon: "gift",
    iconWeb: "IoGift",
    iconWebElement: IoGift,
    title: ""
  },
  { 
    icon: "server",
    iconWeb: "IoServer",
    iconWebElement: IoServer,
    title: ""
  }
]

  export default AllIcons