import {
    Link as ChakraLink,
    Text,
    Box,
    Select
} from '@chakra-ui/react'

import React from "react";


const SelectInvoiceType = ({changeValue ,disabled, isInvalid, value, invoiceTypesData}) => {
  return(
        <Box mt="2">
          <Text fontSize={"smaller"} mb={"1"} fontWeight={"bold"}>Flow Type</Text>
          <Select
            value={value}
            onChange={(e) =>{ changeValue(`invoiceType`, e.target.value)}}
            disabled={disabled}
            isInvalid={isInvalid}
          >
            <option value="" disabled selected>Please select the invoice type</option>
            {invoiceTypesData.map((singleCategory)=>{
              return(
                <option key={singleCategory._id+"edit-inv"} value={singleCategory._id}>{singleCategory.title}</option>
              )
            })}
          </Select>
        </Box>
  )
};

export default SelectInvoiceType;